import React, { useEffect, useState } from 'react';
import AppLicationHigherClassificationLevel from '../Charts/ExecutiveDashboard/AppLicationHigherClassificationLevel';
import ComplianceRequirements from '../Charts/ExecutiveDashboard/ComplianceRequirements';
import TopCyberThreats from '../Charts/ExecutiveDashboard/TopCyberThreats';
import BusinessObjectives from '../Charts/ExecutiveDashboard/BusinessObjectives';
import DiscreteSliderMarks from '../components/ExecutiveDashboard/Sliders/Slider';
import DiscreteSliderMarksInveStment from '../components/ExecutiveDashboard/Sliders/InvestmentSlider';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutline from '@mui/icons-material/HelpOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Typography } from '@mui/material';
import { useHandleTheme } from '../hooks/useHandleTheme';
import { baseURL } from '../resources/apiClient';
import { useApi } from '../hooks/useApis';
import RiskProfileDashboardDonut from '../Charts/ExecutiveDashboard/RiskProfileDashboardDonut';
import ManageBusinessProcessAdmin from './../ClientAdmin/ManageBusinessProcessAdmin';
import http from '../resources/http';
import TopThreats from './TopThreats';
import { useSelector } from 'react-redux';
import { formatNumber } from '../utils/formatNumbers';
const ExcutiveSummary = () => {
  const { darkMode } = useHandleTheme();
  const [topThreats, setTopThreats] = useState([]);
  const [show, setShow] = useState(false);
  const showApps = () => setShow(!show);
  const [appSeq, setAppSeq] = useState(1);
  const [executiveDetails, setExecutiveDetails] = useState({});
  const usersCurrency = useSelector((state) => state.user)?.client_obj
    ?.currency;
  const data = [];
  // const { data } = useApi(
  //   `${baseURL}/business_process/get-my-business-process`
  // );
  const fetchTopThreats = async () => {
    const topThreaResponse = await http.get(
      `${baseURL}/business_process/business-process-summery`
    );
    setTopThreats(topThreaResponse.data);
    const eecutiveDetails = await http.get(
      `${baseURL}/business_process/executive-dashboard-details`
    );
    setExecutiveDetails(eecutiveDetails.data);
  };
  // useEffect(() => {
  //   fetchTopThreats();
  // }, []);
  const [showMore, setShowMore] = useState(false);
  const toggleMore = () => setShowMore(!showMore);
  console.log(executiveDetails, 'executive dashboard');
  return (
    <div className={`${darkMode ? 'bg-gray-900 ' : ''} flex flex-col h-screen`}>
      <div className="p-10 grid  sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
        <div className="rounded-lg flex flex-col h-full w-full justify-evenly shadow-2xl">
          <div
            className={`${
              darkMode ? 'bg-gray-800' : 'bg'
            } h-fit    text-xl rounded-t-lg font-bold p-2 flex  items-center`}
          >
            <div>
              <div className="flex items-center space-x-2">
                <p>Loss Exposure</p>
                <p className="text-xs ">
                  <Tooltip
                    className="text-3xl cursor-pointer"
                    title={
                      <Typography fontSize={15}>
                        Number assets and vendors
                      </Typography>
                    }
                    placement="top"
                  >
                    <HelpOutline />
                  </Tooltip>
                </p>
              </div>
              <p className="text-xs">
                (Annual Loss Event-{usersCurrency} in Millions)
              </p>
            </div>
          </div>
          <div
            className={` ${
              darkMode ? ' border-b border-blue-900' : ''
            }  h-full text-6xl rounded-b-lg p-2`}
          >
            {executiveDetails?.loss_exposure?.value}
          </div>
        </div>
        <div className="rounded-lg flex flex-col h-full w-full justify-evenly shadow-2xl">
          <div
            className={`${
              darkMode ? 'bg-gray-800' : 'bg'
            } h-fit  text-xl rounded-t-lg font-bold p-2 flex  items-center`}
          >
            <div>
              <div className="flex items-center space-x-2">
                <p>Total Investment</p>
                <p className="text-xs ">
                  <Tooltip
                    className="text-3xl cursor-pointer"
                    title={
                      <Typography fontSize={15}>
                        Number assets and vendors
                      </Typography>
                    }
                    placement="top"
                  >
                    <HelpOutline />
                  </Tooltip>
                </p>
              </div>
              <p className="text-xs">
                BAU + Investment Requirement ({usersCurrency} in Millions)
              </p>
            </div>
          </div>
          <div
            className={` ${
              darkMode ? ' border-b border-blue-900' : ''
            } h-full text-6xl rounded-b-lg p-2`}
          >
            {executiveDetails?.total_investment?.value}
          </div>
        </div>
        <div className="rounded-lg flex flex-col h-full w-full justify-evenly shadow-2xl">
          <div
            className={`${
              darkMode ? 'bg-gray-800' : 'bg'
            } h-fit  text-xl rounded-t-lg font-bold p-2 flex  items-center`}
          >
            <div>
              <div className="flex items-center space-x-2">
                <p>Revenue Contribution</p>

                <p className="text-xs ">
                  <Tooltip
                    className="text-3xl cursor-pointer"
                    title={
                      <Typography fontSize={15}>
                        Number assets and vendors
                      </Typography>
                    }
                    placement="top"
                  >
                    <HelpOutline />
                  </Tooltip>
                </p>
              </div>
              <p className="text-xs">({usersCurrency} in Millions)</p>
            </div>
          </div>
          <div
            className={` ${
              darkMode ? ' border-b border-blue-900' : ''
            } h-full text-6xl rounded-b-lg p-2`}
          >
            {formatNumber(executiveDetails?.revenue_contribution?.value)}
          </div>
        </div>

        <div className="rounded-lg shadow-2xl relative overflow-hidden  w-full h-full">
          <div className="flex flex-col h-full">
            <div
              className={`${
                darkMode ? 'bg-gray-800' : 'bg-gray-600 text-white'
              } p-2   font-semibold h-auto   flex items-center space-x-1`}
            >
              <span className="flex flex-col">
                {appSeq === 1 ? (
                  <span>Application Risk Profile Summary</span>
                ) : appSeq === 2 ? (
                  <span>Compliance requirements</span>
                ) : appSeq === 3 ? (
                  <span>Applications highest data classifications level</span>
                ) : (
                  <span>Application Risk Profile Summary</span>
                )}
              </span>
              {appSeq === 1 ? (
                <span
                  onClick={() => toggleMore()}
                  className="  text-sm cursor-pointer"
                >
                  <HelpOutline className="text-3xl cursor-pointer" />
                </span>
              ) : null}
              {show ? (
                <div
                  onMouseLeave={() => showApps()}
                  className="z-50 bg absolute top-10 p-1.5 right-4 rounded-md justify-end items-end"
                >
                  <p onClick={() => setAppSeq(1)}>Risk Profile Summary</p>
                  <p onClick={() => setAppSeq(2)}>Classification Level</p>
                  <p onClick={() => setAppSeq(3)}>Compliance Requirements</p>
                </div>
              ) : null}
              <span
                onClick={() => showApps()}
                className="text-sm absolute right-4 cursor-pointer justify-end ites-end"
              >
                <MoreVertIcon className="text-3xl cursor-pointer" />
              </span>
            </div>
            <div className="p-2   rounded-b-lg h-full w-full flex justify-center items-center">
              {appSeq === 1 ? (
                <RiskProfileDashboardDonut darkMode={darkMode} data={data} />
              ) : appSeq === 2 ? (
                <AppLicationHigherClassificationLevel
                  darkMode={darkMode}
                  data={topThreats}
                />
              ) : appSeq === 3 ? (
                <ComplianceRequirements darkMode={darkMode} data={topThreats} />
              ) : (
                <RiskProfileDashboardDonut darkMode={darkMode} data={data} />
              )}
            </div>
          </div>
        </div>
        {/* <div className="rounded-lg shadow-2xl relative overflow-hidden  w-full h-full">
          <div className="flex flex-col h-full">
            <div
              className={`${
                darkMode ? 'bg-gray-800' : 'bg-gray-600 text-white'
              } p-2   font-semibold h-auto   flex items-center space-x-1`}
            >
              <span className="flex flex-col">
                <span>Applications highest data classifications level</span>
              </span>
              <span
                onClick={() => toggleMore()}
                className="  text-sm cursor-pointer"
              >
                <HelpOutline className="text-3xl cursor-pointer" />
              </span>
            </div>
            <div className="p-2   rounded-b-lg h-full w-full flex justify-center items-center">
              <AppLicationHigherClassificationLevel
                darkMode={darkMode}
                data={topThreats}
              />{' '}
            </div>
          </div>
        </div>
        <div className="rounded-lg shadow-2xl relative overflow-hidden  w-full h-full">
          <div className="flex flex-col h-full">
            <div
              className={`${
                darkMode ? 'bg-gray-800' : 'bg-gray-600 text-white'
              } p-2   font-semibold h-auto   flex items-center space-x-1`}
            >
              <span className="flex flex-col">
                <span>Compliance requirements</span>
              </span>
              <span
                onClick={() => toggleMore()}
                className="  text-sm cursor-pointer"
              >
                <HelpOutline className="text-3xl cursor-pointer" />
              </span>
            </div>
            <div className="p-2   rounded-b-lg h-full w-full flex justify-center items-center">
              <ComplianceRequirements darkMode={darkMode} data={topThreats} />{' '}
            </div>
          </div>
        </div> */}
        <div className="rounded-lg shadow-2xl relative overflow-hidden  w-full h-full">
          <div className="flex flex-col h-full">
            <div
              className={`${
                darkMode ? 'bg-gray-800' : 'bg-gray-600 text-white'
              } p-2   font-semibold h-auto   flex items-center space-x-1`}
            >
              <span className="flex flex-col">
                <span>Top Five Threats</span>
              </span>
              <span className="  text-sm cursor-pointer">
                <HelpOutline className="text-3xl cursor-pointer" />
              </span>
            </div>
            <div className="rounded-b-lg h-full w-full flex justify-center items-center">
              <TopThreats />{' '}
            </div>
          </div>
        </div>
        <div className="rounded-lg shadow-2xl relative overflow-hidden  w-full h-full">
          <div className="flex flex-col h-full">
            <div
              className={`${
                darkMode ? 'bg-gray-800' : 'bg-gray-600 text-white'
              } p-2   font-semibold h-auto  flex space-x-1`}
            >
              <span className="flex flex-col">
                <span>Top Cyber Risks</span>
                <span>(in%)</span>
              </span>
              <span className=" text-sm cursor-pointer">
                <Tooltip
                  className="text-3xl cursor-pointer"
                  title={
                    <Typography fontSize={15}>
                      Number assets and vendors
                    </Typography>
                  }
                  placement="top"
                >
                  <HelpOutline />
                </Tooltip>
              </span>
            </div>
            <div className="p-2  rounded-b-lg h-full w-full flex justify-center items-center">
              <TopCyberThreats darkMode={darkMode} />{' '}
            </div>
          </div>
        </div>
        <div className="rounded-lg shadow-2xl relative overflow-hidden  w-full h-full">
          <div className="flex flex-col h-full">
            <div
              className={`${
                darkMode ? 'bg-gray-800' : 'bg-gray-600 text-white'
              } p-2   font-semibold h-auto  flex space-x-1`}
            >
              <span className="flex flex-col">
                <span>Contribution to Business Objectives</span>
                <span>(in%)</span>
              </span>
              <span className=" text-sm cursor-pointer">
                <Tooltip
                  className="text-3xl cursor-pointer"
                  title={
                    <Typography fontSize={15}>
                      Number assets and vendors
                    </Typography>
                  }
                  placement="top"
                >
                  <HelpOutline />
                </Tooltip>
              </span>
            </div>
            <div className="p-2   rounded-b-lg h-full w-full flex justify-center items-center">
              <BusinessObjectives darkMode={darkMode} />{' '}
            </div>
          </div>
        </div>
        <div className="rounded-lg shadow-2xl h-56  flex flex-col  overflow-hidden ">
          <div
            className={`${
              darkMode ? 'bg-gray-800' : 'bg-gray-600 text-white'
            } flex  rounded-t-lg   justify-center items-start p-2 flex-col`}
          >
            <span className="flex justify-center  items-center  font-semibold space-x-1">
              <span>Investment Requirement</span>
              <span className=" text-sm cursor-pointer">
                <Tooltip
                  className="text-3xl cursor-pointer"
                  title={
                    <Typography fontSize={15}>
                      Number assets and vendors
                    </Typography>
                  }
                  placement="top"
                >
                  <HelpOutline />
                </Tooltip>
              </span>
            </span>
            <span className="inline-block   text-sm font-semibold">
              ({usersCurrency} in Millions)
            </span>
          </div>
          <div className=" rounded-b-lg h-full">
            <div className="flex flex-col justify-center items-center">
              <div className="flex text-sm space-x-4 p-5">
                <span className="text-xl font-bold mt-2">0</span>
                <span className="text-4xl mx-36 color font-bold">
                  <DiscreteSliderMarksInveStment darkMode={darkMode} />
                </span>
                <span className="text-xl font-bold mt-2">10</span>
              </div>
            </div>
            <div className="space-x-80 flex justify-center items-center  -mt-16">
              <span className="inline-block -mt-6 py-1 text-sm font-semibold  mr-2 mb-2">
                Min
              </span>
              <span className="inline-block -mt-6 text-sm font-semibold  mr-2 mb-2">
                Max
              </span>
            </div>
          </div>
        </div>
        <div className="rounded-lg shadow-2xl h-56  flex flex-col  overflow-hidden ">
          <div
            className={`${
              darkMode ? 'bg-gray-800' : 'bg-gray-600 text-white'
            } flex  rounded-t-lg   justify-center items-start p-2 flex-col`}
          >
            <span className="flex justify-center rounded-t-lg  items-center  font-semibold space-x-1">
              <span>Risk Reduction</span>
              <span className=" text-sm cursor-pointer">
                <Tooltip
                  className="text-3xl cursor-pointer"
                  title={
                    <Typography fontSize={15}>
                      Number assets and vendors
                    </Typography>
                  }
                  placement="top"
                >
                  <HelpOutline />
                </Tooltip>
              </span>
            </span>
            <span className="inline-block   text-sm font-semibold">(in %)</span>
          </div>
          <div className="  rounded-b-lg h-full">
            <div className="flex flex-col justify-center items-center">
              <div className="flex text-sm space-x-4 p-5">
                <span className="text-xl font-bold mt-2">0</span>
                <span className="text-4xl flex justify-center items-center color font-bold">
                  <DiscreteSliderMarks darkMode={darkMode} />
                </span>
                <span className="text-xl font-bold mt-2">100</span>
              </div>
            </div>
            <div className="space-x-80 flex justify-center items-center  -mt-16">
              <span className="inline-block -mt-6 py-1 text-sm font-semibold  mr-2 mb-2">
                Min
              </span>
              <span className="inline-block -mt-6 text-sm font-semibold  mr-2 mb-2">
                Max
              </span>
            </div>
          </div>
        </div>
      </div>
      {showMore ? (
        <div
          id="defaultModal"
          class="flex justify-center items-center fixed  z-50  w-full  overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
        >
          <div class="relative ">
            <div class="relative flex flex-col bg-white rounded-lg shadow dark:bg-gray-700">
              <button
                type="button"
                class="flex justify-end items-end pr-4 "
                data-modal-hide="popup-modal"
                onClick={() => toggleMore()}
              >
                <svg
                  class="w-6 h-6 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span class="sr-only">Close</span>
              </button>
              <div class=" text-center">
                <ManageBusinessProcessAdmin isPopUp={true} />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ExcutiveSummary;
