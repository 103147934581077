import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import swal from 'sweetalert';
import http from '../../../resources/http';
import {
  CONFIDENTIALITY_LEVELS,
  CONFIDENTIALITY_LEVEL_BY_PK,
} from '../../../api/threatCatalog';
import CustomTable from '../../../shared/CustomTable';
import SearchHandler from '../../../shared/SearchHandler';
import SearchInput from '../../../shared/SearchInput/SearchInput';
import LoadingComponent from '../../../shared/LoadingDropDownSelection/LoadingComponent';
import ModalHandler from './ModalHandler';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CONFIDENTIALITY_LEVELS } from '../../../graphql/superAdmin/query/query';
import {
  ADD_CONFIDENTIALITY,
  DELETE_CONFIDENTIALITY,
  UPDATE_CONFIDENTIALITY,
} from '../../../graphql/superAdmin/mutation/mutation';

const Confidentiality = () => {
  const url_create = CONFIDENTIALITY_LEVELS;
  const url_by_pk = CONFIDENTIALITY_LEVEL_BY_PK;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mode, setMode] = useState('add'); // 'add' or 'edit'
  const [selectedItem, setSelectedItem] = useState(null);

  const [id, setId] = useState();
  const [data, setData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const fieldsToFilterBy = ['label', 'value', 'description'];

  const [update] = useMutation(UPDATE_CONFIDENTIALITY);
  const [create] = useMutation(ADD_CONFIDENTIALITY);

  //data

  const [page, setPage] = useState(0);
  const pageSize = 10; // Number of items per page
  const [del] = useMutation(DELETE_CONFIDENTIALITY);

  const {
    data: confidentiality,
    loading,
    error,
    refetch,
  } = useQuery(GET_CONFIDENTIALITY_LEVELS, {
    variables: { limit: pageSize, offset: page * pageSize },
  });
  const handlePageChange = ({ selected }) => {
    setPage(selected);
    refetch({ limit: pageSize, offset: selected * pageSize });
  };
  const totalItems =
    confidentiality?.confidentiality_level_aggregate?.count || 0;
  // useEffect(() => {
  // 	          // fetchData();
  // }, []);

  const filteredData = SearchHandler(
    confidentiality?.confidentiality_level ?? [],
    searchValue,
    fieldsToFilterBy
  );

  const handleInputChange = (newValue) => {
    setSearchValue(newValue);
  };
  // Handle the edit click from the table
  const handleEdit = (id, item) => {
    setSelectedItem(item);
    setId(id);
    setMode('edit');
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    setFetchLoading(true);
    try {
      const { data } = await del({
        variables: { id: parseInt(id) },
      });
      if (data?.delete_confidentiality_level?.affected_rows > 0) {
        setFetchLoading(false);
        refetch();
        swal('Success', 'It has been deleted successfully', 'success');
      } else {
      }
    } catch (error) {
      setFetchLoading(false);
      console.error('Failed to delete item:', error);
    }
  };
  return (
    <main>
      <div className="main-container">
        <div className="table-title ">
          <span className=" ">Confidentiality</span>
          <div className="flex justify-between items-center space-x-4">
            <SearchInput
              searchValue={searchValue}
              setSearchValue={handleInputChange}
            />
            <button
              className="btn-add-new"
              onClick={() => {
                setSelectedItem(null);
                setMode('add');
                setIsModalOpen(true);
              }}
            >
              <AddIcon />
            </button>
          </div>
        </div>
        {/* table */}
        {fetchLoading ? (
          <LoadingComponent />
        ) : searchValue.length > 0 && filteredData.length === 0 ? (
          <div className="alert-no-data">
            No data available for
            <span className="search-value">{searchValue}</span>
            at the moment.
          </div>
        ) : (
          <CustomTable
            filteredData={filteredData}
            onEditClick={handleEdit}
            onDeleteClick={handleDelete}
            page={page}
            pageSize={pageSize}
            totalItems={totalItems}
            onPageChange={handlePageChange}
            passedExcludeColumns={['__typename']}
          />
        )}
        {/* add and edit modal */}
        <ModalHandler
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          mode={mode}
          size="xl"
          selectedItemId={id}
          selectedItem={selectedItem}
          header="Confidentiality"
          url_create={url_create}
          url_by_pk={data.confidentiality_level}
          createMutation={create}
          updateMutation={update}
          create_object={'create_confidentiality_level'}
          update_object={'update_confidentiality_level'}
          fetchData={refetch}
        />
      </div>
    </main>
  );
};

export default Confidentiality;
