import React, { useState, useMemo } from "react";
import SvgComponent from "./SvgComponent";

function CronusSelect({ options = [], onChange, value = "" }) {
	const [isOpen, setIsOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");

	const toggleDropdown = () => setIsOpen((prev) => !prev);

	const filteredOptions = useMemo(() => {
		const uniqueOptions = Array.from(new Set(options));
		if (!searchTerm) return uniqueOptions;
		return uniqueOptions.filter((option) =>
			option.toLowerCase().includes(searchTerm.toLowerCase())
		);
	}, [options, searchTerm]);

	const selectOption = (option) => {
		onChange({ target: { value: option } });
		setIsOpen(false);
		setSearchTerm("");
	};

	return (
		<div className="CronusSelect" role="combobox" aria-expanded={isOpen}>
			<div
				className="dropdown-header"
				onClick={toggleDropdown}
				role="button"
				tabIndex="0"
				aria-haspopup="true"
				onKeyDown={(e) => {
					if (e.key === "Enter" || e.key === " ") toggleDropdown();
				}}
			>
				<div className="dropdown-header-value">
					<span>{value || "Select..."}</span>
				</div>
				<SvgComponent
					width="24"
					height="24"
					fillColor="none"
					strokeColor="currentColor"
					strokeWidth="2"
					path={isOpen ? "M18 15 12 9 6 15" : "M6 9L12 15 18 9"}
				/>
			</div>

			{isOpen && (
				<div
					className="dropdown-menu"
					role="menu"
					onMouseLeave={() => {
						toggleDropdown();
					}}
				>
					<div className="select-content">
						<div className="dropdown-search">
							<input
								type="text"
								placeholder="Search..."
								value={searchTerm}
								onChange={(e) => setSearchTerm(e.target.value)}
								aria-label="Search options"
							/>
							{searchTerm && (
								<button
									type="button"
									onClick={() => setSearchTerm("")}
									className="dropdown-search-clear-btn"
									aria-label="Clear search"
								>
									<SvgComponent
										width="24"
										height="24"
										fillColor="none"
										strokeColor="currentColor"
										strokeWidth="2"
										path="M18 6L6 18M6 6l12 12"
									/>
								</button>
							)}
						</div>
						<ul className="options" role="listbox">
							{filteredOptions.length ? (
								filteredOptions.map((option) => (
									<li
										key={option}
										role="menuitem"
										tabIndex="0"
										onClick={() => selectOption(option)}
										onKeyDown={(e) => {
											if (e.key === "Enter") selectOption(option);
										}}
									>
										<span>{option}</span>
									</li>
								))
							) : (
								<li role="menuitem" tabIndex="0">
									<span>No options found</span>
								</li>
							)}
						</ul>
					</div>
				</div>
			)}
		</div>
	);
}

export default CronusSelect;
