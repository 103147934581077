import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import http from "../../../resources/http";
import { Button, Form, Modal } from "react-bootstrap";
import AddIcon from "@mui/icons-material/Add";
import { downloadConsensus } from "../../../utils/ExportExcelUtility";
import UploadConsensus from "./UploadConsensus";
import swal from "sweetalert";
import SearchInput from "../../../shared/SearchInput/SearchInput";
import {
	CONSENSUS_ASSESSMENT_QUESTIONNAIRE_LIST,
	CONSENSUS_ASSESSMENT_QUESTIONNAIRE_ADD,
	CONSENSUS_ASSESSMENT_TITLES,
} from "../../../api/excelUpload";
const ViewConsensus = () => {
	const [data, setData] = useState([]);
	const [title, setTitle] = useState("Application & Interface Security - AIS");
	const [searchValue, setSearchValue] = useState("");
	const filteredData = data?.[title]?.filter(
		(row) =>
			row?.consensus_assessment_question?.match(new RegExp(searchValue, "i")) ||
			row?.control_title?.match(new RegExp(searchValue, "i")) ||
			row?.question_id?.match(new RegExp(searchValue, "i")) ||
			row?.control_id?.match(new RegExp(searchValue, "i")) ||
			row?.control_title?.match(new RegExp(searchValue, "i"))
	);
	const [pageNumber, setPageNumber] = useState(0);
	const usersPerPage = 6;
	const pagesVisited = pageNumber * usersPerPage;
	const pageCount = Math.ceil(filteredData?.length / usersPerPage);
	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};
	const [selected, setSelected] = useState([]);

	const [fetchTitle, setFetchedTitle] = useState([]);

	const [showAdd, setShowAdd] = useState(false);
	const handleShowAdd = () => setShowAdd(!showAdd);

	const [consensus, setConsensus] = useState({
		question_id: "",
		consensus_assessment_question: "",
		control_specification: "",
		control_title: "",
		control_id: "",
	});

	//toggle add
	const [showFileUploader, setShowFileUploader] = useState(false);
	const toggleFileUploader = () => setShowFileUploader(!showFileUploader);

	const addNewRecord = (e) => {
		e.preventDefault();
		http
			.post(`${CONSENSUS_ASSESSMENT_QUESTIONNAIRE_ADD}`, {
				question_id: consensus.question_id,
				consensus_assessment_question: consensus.consensus_assessment_question,
				control_id: consensus.control_id,
				control_title: consensus.control_title,
				control_specification: consensus.control_specification,
			})
			.then((response) => {
				handleShowAdd();
				swal("Success", "It has been added successfully.", "success");
				// fetchData();
			})
			.catch((err) => {
				swal("Error", "Something went wrong, please try again.", "error");
			});
	};

	const fetchData = () => {
		http
			.get(`${CONSENSUS_ASSESSMENT_QUESTIONNAIRE_LIST}`)
			.then((response) => {
				setData(response.data);
				JSON.stringify(data, "your data");
			})
			.then(
				(response) => { },
				(err) => {
					console.log("No Data To Show");
				}
			)
			.catch((err) => {
				return false;
			});
		http
			.get(`${CONSENSUS_ASSESSMENT_TITLES}`)
			.then((response) => {
				setFetchedTitle(response.data);
			})
			.then(
				(response) => { },
				(err) => {
					swal(
						"There is no data to display at the moment, please try again later."
					);
				}
			)
			.catch((err) => {
				return false;
			});
	};
	// useEffect(() => {
	// 	          // fetchData();
	// }, []);

	const handleInputChange = (newValue) => {
		setSearchValue(newValue);
		setPageNumber(0); // Reset to the first page when search value changes
	};

	const DisplayData = filteredData
		?.slice(pagesVisited, pagesVisited + usersPerPage)
		?.map((asset) => {
			return (
				<tr>
					<td>{asset?.question_id}</td>
					<td>{asset?.consensus_assessment_question}</td>
					<td>{asset?.control_id}</td>
					<td>{asset?.control_title}</td>
					<td>{asset?.control_specification}</td>
				</tr>
			);
		});
	return (
		<div className="main-container">
			<div className="table-title ">
				<span className="font-bold ">Consensus Data</span>{" "}
				<div className="w-64">
					<label
						for="countries"
						class="block mb-2 text-sm font-semibold text-white dark:text-gray-400"
					>
						Select Title
					</label>
					<select
						value={title}
						onChange={(e) => {
							setTitle(e.target.value);
						}}
						id="countries"
						class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
					>
						{fetchTitle?.map((x) => (
							<option value={x}>{x}</option>
						))}
					</select>
				</div>{" "}
				<div className="flex justify-between items-center space-x-4">
					<SearchInput
						searchValue={searchValue}
						setSearchValue={handleInputChange}
					/>
					<button
						onClick={() => toggleFileUploader()}
						className="btn_file_download"
					>
						<img
							alt="upload-avatar"
							className="w-7 h-7"
							src="https://cdn0.iconfinder.com/data/icons/upload-download-files/128/file_xlsx_excel_document_upload-27-512.png"
						/>
					</button>

					<button className="btn_file_download">
						<svg
							onClick={() =>
								downloadConsensus(data?.[title], "Consensus Assessment")
							}
							xmlns="http://www.w3.org/2000/svg"
							height={28}
							width={28}
							fill-rule="evenodd"
							fill="white"
							clip-rule="evenodd"
							image-rendering="optimizeQuality"
							shape-rendering="geometricPrecision"
							text-rendering="geometricPrecision"
							viewBox="0 0 17639 17639"
						>
							<path
								fill="#1e7145"
								d="M9596 3634v10265l-6103-1058V4692l6103-1058zm290 1185h4053c224 0 267 44 267 267v7360c0 223-43 267-267 267H9886v-694h1547v-1066H9886v-320h1547V9566H9886v-320h1547V8179H9886v-320h1547V6793H9886v-320h1547V5406H9886v-587zM2083 15593h13474l2-13476H2081l2 13476z"
							/>
							<path
								fill="#1e7145"
								d="m7129 6861-556 1272-432-1195-695 31 690 1741-777 1703 683 51 548-1225 510 1296 786 43-852-1875 815-1894zM11753 12019h1760v-1066h-1760zM11753 10633h1760V9566h-1760zM11753 9246h1760V8179h-1760zM11753 6473h1760V5406h-1760zM11753 7859h1760V6793h-1760z"
							/>
						</svg>
					</button>
					<button
						onClick={(e) => {
							handleShowAdd();
						}}
						className="btn-add-new"
					>
						<AddIcon />
					</button>
				</div>
			</div>

			<table className="tables">
				<thead className="sticky-header">
					<tr>
						<th className="">Question ID</th>
						<th className="">Assessment Question</th>
						<th className="">Control ID</th>
						<th className="">Control Title</th>
						<th className="">Control Specification</th>
					</tr>
				</thead>
				<tbody>{DisplayData}</tbody>
			</table>
			{filteredData?.length > 6 ? (
				<ReactPaginate
					previousLabel={"<"}
					nextLabel={">"}
					activeClassName={"paginationActive"}
					pageCount={pageCount}
					onPageChange={changePage}
					containerClassName={"paginationBttns"}
					previousLinkClassName={"previousBttn"}
					nextLinkClassName={"nextBttn"}
					disabledClassName={"paginationDisabled"}
				/>
			) : null}

			{/* upload model */}

			{showFileUploader ? (
				<UploadConsensus
					fetchData={fetchData}
					toggleFileUploader={toggleFileUploader}
				/>
			) : null}

			{/* adding model */}
			<Modal show={showAdd}>
				<Modal.Header onClick={() => handleShowAdd()}>
					<Modal.Title>Add New Consensus Data</Modal.Title>
				</Modal.Header>
				<Modal.Body className="modal-body-medium">
					<Form.Group class="mb-6">
						<Form.Label
							for="email"
							class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
						>
							Question ID
						</Form.Label>
						<Form.Control
							type="email"
							id="email"
							class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
							placeholder="Question ID..."
							required
							value={consensus.question_id}
							onChange={(e) =>
								setConsensus({
									...consensus,
									question_id: e.target.value,
								})
							}
							name="email"
						></Form.Control>
					</Form.Group>
					<Form.Group class="mb-6">
						<Form.Label
							for="email"
							class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
						>
							Assessment Question
						</Form.Label>
						<Form.Control
							type="email"
							id="email"
							class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
							value={consensus.consensus_assessment_question}
							onChange={(e) =>
								setConsensus({
									...consensus,
									consensus_assessment_question: e.target.value,
								})
							}
							placeholder="Assessment Question..."
							// isInvalid={!!errors.asset}
							required
							name="email"
						></Form.Control>
					</Form.Group>
					<Form.Group class="mb-6">
						<Form.Label
							for="email"
							class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
						>
							Control ID
						</Form.Label>
						<Form.Control
							type="email"
							id="email"
							class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
							value={consensus.control_id}
							onChange={(e) =>
								setConsensus({
									...consensus,
									control_id: e.target.value,
								})
							}
							placeholder="Control ID..."
							// isInvalid={!!errors.asset}
							required
							name="email"
						></Form.Control>
					</Form.Group>
					<Form.Group class="mb-6">
						<Form.Label
							for="email"
							class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
						>
							Control Title
						</Form.Label>
						<Form.Control
							type="email"
							id="email"
							class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
							value={consensus.control_title}
							onChange={(e) =>
								setConsensus({
									...consensus,
									control_title: e.target.value,
								})
							}
							placeholder="Control Title..."
							// isInvalid={!!errors.asset}
							required
							name="email"
						></Form.Control>
					</Form.Group>
					<Form.Group class="mb-6">
						<Form.Label
							for="email"
							class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
						>
							Control Specification
						</Form.Label>
						<Form.Control
							type="email"
							id="email"
							class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
							value={consensus.control_specification}
							onChange={(e) =>
								setConsensus({
									...consensus,
									control_specification: e.target.value,
								})
							}
							placeholder="Control Specification..."
							// isInvalid={!!errors.asset}
							required
							name="email"
						></Form.Control>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button className="btn-cancel" onClick={() => handleShowAdd()}>
						Cancel
					</Button>
					<Button className="btn-add-new" onClick={(e) => addNewRecord(e)}>
						Add
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default ViewConsensus;
