import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import http from '../../../resources/http';
import { baseURL } from '../../../resources/apiClient';
import MoreButton from '../../../layout/components/MoreButton';
import { uniqueArray } from './../../../utils/uniqueArrayOfObjects';

const ThreatModeling = () => {
  const [details, setDetails] = useState(false);
  const [showThreatScenario, setShowThreatScenario] = useState(false);

  const [sensFilter, setRadioFilter] = useState('High');
  const [scenarioText, setScenarioText] = useState('');
  const [showPopUp, setShowPopUp] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 12;
  const pagesVisited = pageNumber * usersPerPage;
  const [loading, setLoading] = useState(true);
  const [threatModeling, setThreatModeling] = useState([]);
  let numberFormat = new Intl.NumberFormat('en-US');
  const pageCount = Math.ceil(threatModeling.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const [selectedBusinessProcessId, setSelectedBusinessProcessId] = useState();
  const [selectedClientBusinessProcesses, setSelectedClientBusinessProcesses] =
    useState([]);

  const fetchData = async () => {
    try {
      const response = await http.get(
        `${baseURL}/business_process/get-my-business-process`
      );
      const responseData = response.data;
      setSelectedClientBusinessProcesses(responseData);

      const responseModeling = await http.get(
        `${baseURL}/business_process/threat-modeling?businessProcessId=${selectedBusinessProcessId}`
      );
      setThreatModeling(responseModeling.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  // useEffect(() => {
  //             // fetchData();
  // }, [selectedBusinessProcessId]);

  const DisplayData = threatModeling?.map((threats) => {
    return (
      <>
        <tr key={threats?.id}>
          <td>{threats?.records[0]?.name}</td>
          <td>
            {threats?.records[0]?.description ? (
              <div className="w-52">
                <MoreButton
                  description={threats?.records[0]?.description}
                  stringLength={25}
                />
              </div>
            ) : (
              'N/A'
            )}
          </td>
          <td>
            {threats?.records[0]?.threat_senario ? (
              <div className="w-52">
                <MoreButton
                  description={threats?.records[0]?.threat_senario}
                  stringLength={25}
                />
              </div>
            ) : (
              'N/A'
            )}
          </td>

          <td className="text-center">
            {threats?.records[0]?.event_frequency}
          </td>
          <td className="text-center">
            {numberFormat.format(threats?.records[0]?.cost_of_incident)}
          </td>
          <td className="text-center">
            {numberFormat.format(threats?.records[0]?.cost_of_breach)}
          </td>
          <td className=" font-semibold">
            <p
              className="p-3 text-center rounded-xl"
              style={{
                backgroundColor:
                  threats?.records[0]?.threat_severity.severity.High ===
                    'low' && sensFilter === 'High'
                    ? 'green'
                    : threats?.records[0]?.threat_severity.severity.High ===
                      'very_low' && sensFilter === 'High'
                      ? '#90ee90'
                      : threats?.records[0]?.threat_severity.severity.High ===
                        'moderate' && sensFilter === 'High'
                        ? 'yellow'
                        : threats?.records[0]?.threat_severity.severity.High ===
                          'high' && sensFilter === 'High'
                          ? 'orange'
                          : threats?.records[0]?.threat_severity.severity.High ===
                            'critical' && sensFilter === 'High'
                            ? 'maroon'
                            : threats?.records[0]?.threat_severity.severity.Medium ===
                              'low' && sensFilter === 'Medium'
                              ? 'green'
                              : threats?.records[0]?.threat_severity.severity.Medium ===
                                'very_low' && sensFilter === 'Medium'
                                ? '#90ee90'
                                : threats?.records[0]?.threat_severity.severity.Medium ===
                                  'moderate' && sensFilter === 'Medium'
                                  ? 'yellow'
                                  : threats?.records[0]?.threat_severity.severity.Medium ===
                                    'high' && sensFilter === 'Medium'
                                    ? 'orange'
                                    : threats?.records[0]?.threat_severity.severity.Medium ===
                                      'critical' && sensFilter === 'Medium'
                                      ? 'maroon'
                                      : threats?.records[0]?.threat_severity.severity.Low ===
                                        'low' && sensFilter === 'Low'
                                        ? 'green'
                                        : threats?.records[0]?.threat_severity.severity.Low ===
                                          'very_low' && sensFilter === 'Low'
                                          ? '#90ee90'
                                          : threats?.records[0]?.threat_severity.severity.Low ===
                                            'moderate' && sensFilter === 'Low'
                                            ? 'yellow'
                                            : threats?.records[0]?.threat_severity.severity.Low ===
                                              'high' && sensFilter === 'Low'
                                              ? 'orange'
                                              : threats?.records[0]?.threat_severity.severity.Low ===
                                                'critical' && sensFilter === 'Low'
                                                ? 'maroon'
                                                : sensFilter === 'extra high'
                                                  ? 'maroon'
                                                  : 'gray',
                color: 'black',
              }}
            ></p>
          </td>
        </tr>
      </>
    );
  });
  return (
    <div className="p-10 ">
      <div className="table-title">
        <div className="flex justify-between items-center space-x-4">
          <span>Threat Modeling</span>
          <div className="flex justify-end items-center bg-transparent ">
            <div class="pr-2">
              <Form.Control
                as="select"
                className="p-2 w-64"
                value={selectedBusinessProcessId}
                onChange={(e) => {
                  setSelectedBusinessProcessId(e.target.value);
                }}
              >
                <option>Select Business Process</option>
                {selectedClientBusinessProcesses?.map((x, y) => (
                  <option value={x.id}>{x.name}</option>
                ))}
              </Form.Control>
            </div>
          </div>
        </div>
        <div class="flex justify-center items-center ">
          <label className="form-check-label inline-block pr-2 ">
            Sensitivity:{' '}
          </label>
          <input
            class="form-check-input appearance-none rounded-full     h-4 w-4  border border-gray-300  checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200  align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault1"
            onClick={() => setRadioFilter(`Low`)}
          />
          <label
            class="form-check-label inline-block pr-2 "
            for="flexRadioDefault1"
          >
            Low
          </label>
          <input
            class="form-check-input appearance-none rounded-full     h-4 w-4  border border-gray-300  checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200  align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault1"
            onClick={() => setRadioFilter(`Medium`)}
          />
          <label
            class="form-check-label inline-block pr-2 "
            for="flexRadioDefault1"
          >
            Medium
          </label>
          {sensFilter === 'High' ? (
            <input
              checked
              class="form-check-input appearance-none rounded-full     h-4 w-4 border border-gray-300  checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200  align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              onClick={() => setRadioFilter(`High`)}
            />
          ) : (
            <input
              class="form-check-input appearance-none rounded-full     h-4 w-4 border border-gray-300  checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200  align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              onClick={() => setRadioFilter(`High`)}
            />
          )}
          <label class="form-check-label inline-block" for="flexRadioDefault1">
            High
          </label>
        </div>
      </div>{' '}
      <div className="flex justify-center items-center">
        {loading ? (
          <p className="flex justify-center items-center mt-4">
            Please select business process
          </p>
        ) : null}
      </div>
      <div className="overflow-x-auto">
        {' '}
        <table className="tables">
          <thead className="">
            <tr>
              <th className="text-center" scope="col">
                Threat
              </th>
              <th className="text-center" scope="col">
                Threat Description
              </th>
              <th className="text-center" scope="col">
                Threat Scenario
              </th>
              <th className="text-center" scope="col">
                Annual Threat <br />
                Event Frequency (in %)
              </th>
              <th className="text-center" scope="col">
                Cost of Incident(USD)
              </th>
              <th className="text-center" scope="col">
                Cost of Breach(USD)
              </th>

              <th className="text-center" scope="col">
                Threat Severity and Rating
              </th>
            </tr>
          </thead>
          <tbody className="z-10">
            {threatModeling.length ? DisplayData : null}
          </tbody>
        </table>
      </div>
      {threatModeling.length > 8 ? (
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          activeClassName={'paginationActive'}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={'paginationBttns'}
          previousLinkClassName={'previousBttn'}
          nextLinkClassName={'nextBttn'}
          disabledClassName={'paginationDisabled'}
        />
      ) : null}
      <div className="flex  p-4 justify-end items-center">
        <div className="flex space-x-2">
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-green-200"
              fill="#90ee90"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
              />
            </svg>
            <span className="">Very Low</span>
          </div>
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-green-500"
              fill="green"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
              />
            </svg>
            <span className="">Low</span>
          </div>
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-yellow-100"
              fill="yellow"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
              />
            </svg>
            <span className="">Moderate</span>
          </div>
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-yellow-500"
              fill="orange"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
              />
            </svg>
            <span className="">High</span>
          </div>
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-red-800"
              fill="maroon"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
              />
            </svg>
            <span className="">Critical</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreatModeling;
