import React, { useState, useEffect } from 'react';
import LoadingComponent from '../../../shared/LoadingDropDownSelection/LoadingComponent';
import http from '../../../resources/http';
import Modal from '../../../shared/Modal';
import Form from '../../../shared/Form';
import { CURRENCY, CURRENCY_LIST } from '../../../routes/accounts';
import swal from 'sweetalert';

const ModalHandler = ({
  isOpen,
  onClose,
  mode,
  size,
  selectedItemId,
  selectedItem,
  header,
  url,
  fetchData,
}) => {
  const initialState = {
    name: '',
    symbol: '',
  };
  const [fetchLoading, setFetchLoading] = useState(false);
  const [formData, setFormData] = useState({ initialState });
  const [errors, setErrors] = useState({});

  const validations = {
    name: (value) => (value ? '' : 'This field is required.'),
    symbol: (value) => (value ? '' : 'This field is required.'),
  };

  const handleChange = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
    if (validations[key]) {
      const error = validations[key](value);
      setErrors((prevState) => ({ ...prevState, [key]: error }));
    }
  };

  const resetForm = () => {
    setFormData(initialState);
    setErrors({});
  };

  useEffect(() => {
    if (mode === 'edit' && selectedItem) {
      setFormData(selectedItem);
    } else {
      resetForm();
    }
  }, [mode, selectedItem]);

  const formFieldsConfig = {
    name: {
      label: 'Currency Name',
      type: 'text',
      placeholder: 'Text...',
      value: formData?.name,
      onChange: (e) => handleChange('name', e.target.value),
      error: errors.name,
    },
    symbol: {
      label: 'Currency Symbol',
      type: 'text',
      placeholder: 'Symbol...',
      value: formData?.symbol,
      onChange: (e) => handleChange('symbol', e.target.value),
      error: errors.symbol,
    },
  };

  const handleSubmit = () => {
    let newErrors = {};
    Object.entries(formFieldsConfig).forEach(([key, config]) => {
      if (validations[key]) newErrors[key] = validations[key](formData[key]);
    });
    setErrors(newErrors);
    if (Object.values(newErrors).every((error) => !error)) {
      setFetchLoading(true);
      if (mode === 'edit') {
        http
          .patch(`${url}${selectedItemId}`, {
            name: formData.name,
            symbol: formData.symbol,
          })
          .then((res) => {
            setFetchLoading(false);
            onClose();
            swal('Success!', 'It has been updated successfully', 'success');
            // fetchData();
          })
          .catch((error) => {
            setFetchLoading(false);
            if (error && error.response && error.response.data) {
              for (const [key, errorMessage] of Object.entries(
                error.response.data
              )) {
                if (formFieldsConfig[key]) {
                  newErrors[key] = errorMessage[0];
                }
              }
            }
            setErrors(newErrors);
          });
      } else {
        http
          .post(`${CURRENCY_LIST}`, {
            name: formData.name,
            symbol: formData.symbol,
          })
          .then((res) => {
            setFetchLoading(false);
            onClose();
            swal('Success!', 'It has been updated successfully', 'success');
            // fetchData();
          })
          .catch((error) => {
            setFetchLoading(false);
            if (error && error.response && error.response.data) {
              for (const [key, errorMessage] of Object.entries(
                error.response.data
              )) {
                if (formFieldsConfig[key]) {
                  newErrors[key] = errorMessage[0];
                }
              }
              setErrors(newErrors);
            } else {
              swal('Error', 'Something went wrong, please try again', 'error');
            }
          });
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <Modal.Header>
        <Modal.Title>
          {mode === 'edit' ? `Update  ${header}` : `Add New  ${header}`}
        </Modal.Title>
      </Modal.Header>

      {fetchLoading ? (
        <LoadingComponent />
      ) : (
        <Modal.Body className="modal-body-two-columns">
          <Form>
            {Object.entries(formFieldsConfig || {}).map(
              ([key, fieldConfig]) => (
                <Form.Group key={key}>
                  <Form.Label>{fieldConfig.label}</Form.Label>
                  <Form.Control {...fieldConfig} />
                  {errors[key] && (
                    <Form.Control.Feedback type="invalid">
                      {errors[key]}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              )
            )}
          </Form>
        </Modal.Body>
      )}
      <Modal.Footer>
        <button
          className="btn-cancel"
          onClick={() => {
            onClose();
            resetForm();
          }}
        >
          Cancel
        </button>
        <button className="btn-add-new" onClick={handleSubmit}>
          {mode === 'edit' ? 'Save Changes' : 'Add'}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalHandler;
