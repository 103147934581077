import React, { useState, useRef, useCallback } from "react";

const AutocompleteInput = ({
	id,
	placeholder = "Type to search...",
	value = "",
	onChange,
	onSelect,
	fetchSuggestions,
	debounceTime = 200,
	options = [],
	disabled = false,
}) => {
	const [searchTerm, setSearchTerm] = useState(value);
	const [suggestions, setSuggestions] = useState(options);
	const [showSuggestions, setShowSuggestions] = useState(false);
	const fetchTimeout = useRef(null);

	// Handle input changes with debounce
	const handleInputChange = useCallback(
		(e) => {
			const inputValue = e.target.value;
			setSearchTerm(inputValue);

			// Call parent onChange if provided
			if (onChange) onChange(inputValue);

			// Reset suggestions if input is empty
			if (!inputValue) {
				setSuggestions([]);
				setShowSuggestions(false);
				return;
			}

			// Handle fetching suggestions with debounce
			if (fetchSuggestions) {
				if (fetchTimeout.current) clearTimeout(fetchTimeout.current);

				fetchTimeout.current = setTimeout(() => {
					fetchSuggestions(inputValue)
						.then((results) => {
							setSuggestions(results);
							setShowSuggestions(true);
						})
						.catch((error) => {
							console.error("Error fetching suggestions:", error);
							setSuggestions([]);
							setShowSuggestions(false);
						});
				}, debounceTime);
			} else {
				// If no fetchSuggestions, use static options
				const filteredOptions = options.filter((option) =>
					option.toLowerCase().includes(inputValue.toLowerCase())
				);
				setSuggestions(filteredOptions);
				setShowSuggestions(true);
			}
		},
		[fetchSuggestions, options, debounceTime, onChange]
	);

	// Handle option selection
	const handleSelect = (selectedOption) => {
		setSearchTerm(selectedOption);
		if (onSelect) onSelect(selectedOption);
		setShowSuggestions(false);
	};

	return (
		<div>
			<input
				id={id}
				type="text"
				value={searchTerm}
				onChange={handleInputChange}
				placeholder={placeholder}
				aria-label="Search options"
				className="form-control"
			/>

			{showSuggestions && suggestions.length && searchTerm.length > 0 && (
				<div className="dropdown-menu" role="menu">
					<div className="select-content">
						<ul className="options" role="listbox">
							{suggestions.map((suggestion, index) => (
								<li
									key={index}
									tabIndex="0"
									role="menuitem"
									onClick={() => handleSelect(suggestion)}
									onKeyDown={(e) => {
										if (e.key === "Enter") handleSelect(suggestion);
									}}
								>
									{suggestion}
								</li>
							))}
						</ul>
					</div>
				</div>
			)}
		</div>
	);
};

export default AutocompleteInput;
