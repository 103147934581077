import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import swal from 'sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import http from '../resources/http';
import { baseURL } from '../resources/apiClient';
import { removeDuplicates } from '../utils/helpers';

const ControlCatalogEditRecord = ({
  fetchCatalogs,
  handleModalShowHideAdd,
  url,
  dataEdit,
}) => {
  // fetched data var
  console.log(JSON.stringify(dataEdit), 'dataEdit');
  const [datas, setDatas] = useState([]);
  const [threatVectors, setThreatVectors] = useState([]);

  // for page pagination
  const uniqueThreats = removeDuplicates(threatVectors);
  console.log(uniqueThreats, 'threatVectors');

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;

  const [industryUseCaseConfigData, setIndustryUseCaseConfigData] = useState(
    []
  );
  const [industryStandardConfigData, setIndustryStandardConfigData] = useState(
    []
  );
  const [selectedSecondaryThreats, setSelectedSecondaryThreats] = useState([]);
  const secondaryThreatVectorsOptions = threatVectors?.map(({ name }) => ({
    label: name,
    value: name,
  }));
  const [selectedIndustryUseCases, setSelectedIndustryUseCases] = useState([]);
  const IndustryUseCasesOptions = industryUseCaseConfigData?.map(
    ({ title }) => ({
      label: title,
      value: title,
    })
  );
  const [selectedIndustryStandards, setSelectedIndustryStandards] = useState(
    []
  );
  const IndustryStandardsOptions = industryStandardConfigData?.map(
    ({ title }) => ({
      label: title,
      value: title,
    })
  );
  console.log(IndustryUseCasesOptions, 'IndustryUseCasesOptions');
  //form data and error handler
  const [form, setForm] = useState({}); //form

  useEffect(() => {
    setForm({
      control_domain: dataEdit.control_domain,
      control_category: dataEdit.control_category,
      control_name: dataEdit.control_id,
      control_type: dataEdit.control_type,
      control_impact: dataEdit.control_impact,
      control_scope: dataEdit.control_scope,
      control_applicability: dataEdit.control_applicability,
      primary_threat: dataEdit.primary_threat,
      secondary_threat: dataEdit.secondary_threat,
      industry_usecase: dataEdit.industry_usecase,
      industry_standard: dataEdit.industry_standard_text,
      industry_identifier: dataEdit.industry_identifier,
      control_efficacy: dataEdit.control_efficacy,
      rationalized_threats: dataEdit.rationalized_threats,
      control_description: dataEdit.control_description,
      cmsName: dataEdit.cmc_name,
      stride: dataEdit.stride,
      mitre: dataEdit.mitre,
      owasp: dataEdit.owasp,
      example_threat: dataEdit.example_threat,
      iso_nist: dataEdit.iso_nist,
    });
  }, []);
  const [errors, setErrors] = useState({}); //errors
  //character limiter
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      //if there is an error add the error to errors object
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const validateForm = () => {
    const {
      control_domain = dataEdit.control_domain,
      control_category = dataEdit.control_category,
      control_name = dataEdit.control_id,
      control_type = dataEdit.control_type,
      control_impact = dataEdit.control_impact,
      control_scope = dataEdit.control_scope,
      control_applicability = dataEdit.control_applicability,
      primary_threat = dataEdit.primary_threat,
      secondary_threat = dataEdit.secondary_threat,
      industry_usecase = dataEdit.industry_usecase,
      industry_standard = dataEdit.industry_standard_text,
      industry_identifier = dataEdit.industry_identifier,
      control_efficacy = dataEdit.control_efficacy,
      rationalized_threats = dataEdit.rationalized_threats,
      control_description = dataEdit.control_description,
      cmsName = dataEdit.cmc_name,
      stride = dataEdit.stride,
      mitre = dataEdit.mitre,
      owasp = dataEdit.owasp,
      example_threat = dataEdit.example_threat,
      iso_nist = dataEdit.iso_nist,
    } = form; //declare the variables and assign the values from the form object
  };
  console.log(form.control_domain, 'form data');
  console.log('form', 'form data');

  const clearAll = () => {
    form.control_domain = '';
    form.control_category = '';
    form.control_name = '';
    form.control_type = '';
    form.control_impact = '';
    form.control_scope = '';
    form.control_applicability = '';
    form.primary_threat = '';
    form.secondary_threats = '';
    form.industry_usecase = '';
    form.control_description = '';
    setSelectedSecondaryThreats([]);
    setSelectedIndustryUseCases([]);
    setSelectedIndustryStandards([]);
  };
  const [showHide, setSetShowHide] = useState(false);
  const handleModalShowHides = () => {
    setSetShowHide(!showHide);
  };
  const [descriptionText, setDescriptionText] = useState('');
  //configurable datas
  const [controlDomainConfigData, setControlDomainConfigData] = useState([]);
  const [controlCategoryConfigData, setControlCategoryConfigData] = useState(
    []
  );
  const [fetchLoading, setFetchLoading] = useState(false);
  const [controlTypeConfigData, setControlTypeConfigData] = useState([]);
  const [controlImpactConfigData, setControlImpactConfigData] = useState([]);
  const [controlScopeConfigData, setControlScopeConfigData] = useState([]);
  const [controlApplicabilityConfigData, setControlApplicabilityConfigData] =
    useState([]);

  //update fields start
  const [controlId, setControlId] = useState('');
  const [showFileUploader, setShowFileUploader] = useState(false);
  const toggleFileUploader = () => setShowFileUploader(!showFileUploader);

  const [selectErrors, setSelectErrors] = useState('Please select');
  const [submitted, setSubmitted] = useState(false);

  const submit = (e) => {
    e.preventDefault();
    http
      .patch(`${baseURL}${url}/${dataEdit.id}`, {
        control_domain: form.control_domain,
        control_category: form.control_category,
        control_name: form.control_name,
        control_type: form.control_type,
        control_impact: form.control_impact,
        control_scope: form.control_scope,
        control_applicability: form.control_applicability,
        primary_threat: form.primary_threat,
        secondary_threat: selectedSecondaryThreats,
        industry_usecase: selectedIndustryUseCases,
        industry_standard_text: selectedIndustryStandards,
        industry_identifier: form.industry_identifier,
        control_efficacy: form.control_efficacy,
        rationalized_threats: form.rationalized_threats,
        control_description: form.control_description,
        cmc_name: form.cmsName,
        stride: form.stride,
        owasp: form.owasp,
        mitre: form.mitre,
        iso_nist: form.iso_nist,
        example_threat: form.example_threat,
        is_applicable: true,
        is_implemented: true,
      })
      .then(
        (response) => {
          fetchCatalogs();
          handleModalShowHideAdd();
          swal('Success', 'It has been add successfully.', 'success');
        },
        (err) => {
          swal('Error', 'Something went wrong, please try again.', 'error');
        }
      );
  };
  const fetchData = () => {
    http
      .get(`${baseURL}/configurations/control-domain`)
      .then((response) => {
        setControlDomainConfigData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    http
      .get(`${baseURL}/configurations/control-category`)
      .then((response) => {
        setControlCategoryConfigData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    http
      .get(`${baseURL}/excel-upload/control-type`)
      .then((response) => {
        setControlTypeConfigData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    http
      .get(`${baseURL}/excel-upload/control-impact`)
      .then((response) => {
        setControlImpactConfigData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    http
      .get(`${baseURL}/excel-upload/control-scope`)
      .then((response) => {
        setControlScopeConfigData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    http
      .get(`${baseURL}/excel-upload/control-applicability`)
      .then((response) => {
        setControlApplicabilityConfigData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    http
      .get(`${baseURL}/threat-catalog/threat-vectors`)
      .then((response) => {
        setThreatVectors(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    http
      .get(`${baseURL}/configurations/industry-usecase`)
      .then((response) => {
        setIndustryUseCaseConfigData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    http
      .get(`${baseURL}/configurations/industry-standard`)
      .then((response) => {
        setIndustryStandardConfigData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // fetchData();
    fetchCatalogs();
    validateForm();
  }, []);

  useEffect(() => {
    http
      .get(`${baseURL}/excel-upload/control-catalog-domains`)
      .then((response) => {
        setDatas(response.data);
      })
      .then(
        (response) => { },
        (err) => {
          swal(
            'There is no data to display at the moment, please try again later.'
          );
        }
      )
      .catch((err) => {
        return false;
      });
  }, []);

  return (
    <div className="p-10">
      <div className="container-modal grid grid-cols-2 gap-3">
        <Form.Group class="mb-6">
          <Form.Label
            for="text"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Control Domain
          </Form.Label>
          <Form.Control
            as="select"
            type="text"
            id="text"
            value={form.control_domain}
            onChange={(e) => setField('control_domain', e.target.value)}
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            isInvalid={!!errors.control_domain}
            required
            name="text"
          >
            <option>Select...</option>
            {controlDomainConfigData?.map((x, y) => (
              <option value={x.name}>{x.name}</option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.control_domain}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group class="mb-6">
          <Form.Label
            for="text"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Primary Threat (Mitigated)
          </Form.Label>
          <Form.Control
            as="select"
            id="primary_threat_add"
            value={form.primary_threat}
            onChange={(e) => setField('primary_threat', e.target.value)}
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            isInvalid={!!errors.primary_threat}
            required
            name="text"
            placeholder="Enter Primary Threat (Mitigated)"
          >
            <option>Select...</option>
            {uniqueThreats?.map((x, y) => (
              <option value={x.name}>{x.name}</option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.primary_threat}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group class="mb-6">
          <Form.Label
            for="text"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Control Category
          </Form.Label>
          <Form.Control
            as="select"
            type="text"
            id="text"
            value={form.control_category}
            onChange={(e) => setField('control_category', e.target.value)}
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            isInvalid={!!errors.control_category}
            required
            name="text"
          >
            <option>Select...</option>
            {controlCategoryConfigData
              ?.filter((list) => list.title.length > 1)
              ?.map((x, y) => (
                <option value={x.title}>{x.title}</option>
              ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.control_category}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group class="mb-6">
          <Form.Label
            for="text"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            NIST/CSA Id: Control Name
          </Form.Label>
          <Form.Control
            type="text"
            id="text"
            value={form.control_name}
            onChange={(e) => setField('control_name', e.target.value)}
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            isInvalid={!!errors.control_name}
            placeholder="Text..."
            required
            name="text"
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.control_id}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group class="mb-6">
          <Form.Label
            for="text"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Control Type
          </Form.Label>
          <Form.Control
            as="select"
            type="text"
            id="text"
            value={form.control_type}
            onChange={(e) => setField('control_type', e.target.value)}
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            isInvalid={!!errors.control_type}
            required
            name="text"
          >
            <option>Select...</option>
            {controlTypeConfigData?.map((x, y) => (
              <option value={x.control_type}>{x.control_type}</option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.control_type}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group class="mb-6">
          <Form.Label
            for="text"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Control Impact
          </Form.Label>
          <Form.Control
            as="select"
            type="text"
            id="text"
            value={form.control_impact}
            onChange={(e) => setField('control_impact', e.target.value)}
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            isInvalid={!!errors.control_impact}
            required
            name="text"
          >
            <option>Select...</option>
            {controlImpactConfigData?.map((x, y) => (
              <option value={x.control_impact}>{x.control_impact}</option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.control_impact}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group class="mb-6">
          <Form.Label
            for="text"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Control Scope
          </Form.Label>
          <Form.Control
            as="select"
            type="text"
            id="text"
            value={form.control_scope}
            onChange={(e) => setField('control_scope', e.target.value)}
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            isInvalid={!!errors.control_scope}
            required
            name="text"
          >
            <option>Select...</option>
            {controlScopeConfigData?.map((x, y) => (
              <option value={x.control_scope}>{x.control_scope}</option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.control_scope}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group class="mb-6">
          <Form.Label
            for="text"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Control Applicability
          </Form.Label>
          <Form.Control
            as="select"
            type="text"
            id="text"
            value={form.control_applicability}
            onChange={(e) => setField('control_applicability', e.target.value)}
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            isInvalid={!!errors.control_applicability}
            required
            name="text"
          >
            <option>Select...</option>
            {controlApplicabilityConfigData?.map((x, y) => (
              <option value={x.control_applicability}>
                {x.control_applicability}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.control_applicability}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group class="mb-6">
          <Form.Label
            for="text"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Control Description
          </Form.Label>

          <Form.Control
            as="textarea"
            type="text"
            id="control_description"
            value={form.control_description}
            onChange={(e) => setField('control_description', e.target.value)}
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            isInvalid={!!errors.control_description}
            required
            name="text"
            placeholder="Text..."
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.control_description}
          </Form.Control.Feedback>
        </Form.Group>
      </div>
      <div className="flex justify-end items-end space-x-4 mt-4">
        <Button
          className="btn-cancel"
          onClick={() => {
            handleModalShowHideAdd();
          }}
        >
          Cancel
        </Button>
        <Button
          className="btn-add-new"
          onClick={(e) => {
            submit(e);
          }}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default ControlCatalogEditRecord;
