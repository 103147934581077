import React, { useEffect, useState } from "react";
import { RiArrowDownSFill, RiArrowRightDownFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { GET_MY_BUSINESS_PROCESS } from "../../../api/businessProcess";
import cronusLogo from "../../../assets/imgs/logo.svg";
import { name, pid } from "../../../authentication/store/actions";
import http from "../../../resources/http";
import { capitalizeFirstLetter } from "../../../utils/helpers";
import ClientNavbarItems from "./ClientNavbarItems";
import { GET_BUSINESS_PROCESS } from "../../../graphql/client/query";
import { useQuery } from "@apollo/client";

const ClientNavbar = () => {
  const [activeSecondaryDropdown, setActiveSecondaryDropdown] = useState(null);
  const [businessProcessList, setBusinessProcessList] = useState([]);
  const [activeSubLink, setActiveSubLink] = useState(null);

  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeLink, setActiveLink] = useState(null);

  const processNames = useSelector((state) => state.name);
  // const processName = businessProcessList?.[0]?.name;
  // const bpId = businessProcessList?.[0]?.id;

  const dispatch = useDispatch();

  try {
    var companyInfo = JSON.parse(localStorage.getItem("cyber-minds"));
    var hasAdminPermission = companyInfo?.user?.is_client_admin;
    var company_name = companyInfo?.user?.name;
  } catch (e) {
    // <Redirect to="/signin" />;
  }

  // useEffect(() => {
  //   http
  //     .get(GET_MY_BUSINESS_PROCESS)
  //     .then((response) => {
  //       setBusinessProcessList(response.data);
  //       dispatch(pid(response?.data?.[0]?.id));
  //       dispatch(name(response?.data?.[0]?.name));
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);
  const { data, loading, error, refetch } = useQuery(GET_BUSINESS_PROCESS, {
    variables: { limit: 10},
  });
  const processName = data?.business_process?.[0]?.name;
  const bpId = data?.business_process?.[0]?.id;
  const handleDropdown = (id) => {
    if (activeDropdown === id) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(id);
    }
  };
  const handleSecondaryDropdown = (id) => {
    if (activeSecondaryDropdown === id) {
      setActiveSecondaryDropdown(null);
    } else {
      setActiveSecondaryDropdown(id);
    }
  };
  return (
    <nav className="nav">
      <div className="client-bp-header">
        <img className="cronus-logo" src={cronusLogo} alt="Cronus logo" />
        <ul>
          <li className="nav-content">{company_name}</li>|
          <li
            className="nav-content"
            onClick={() => {
              handleDropdown(processName);
              setActiveLink(processName);
            }}
          >
            {processName?.length > 0 ? processNames : processName}
            {processName && <RiArrowDownSFill className="dropdown-icon" />}
            {processName && activeDropdown === processName && (
              <div
                onMouseLeave={() => {
                  setActiveDropdown(null);
                  setActiveSecondaryDropdown(null);
                }}
                className="nav-dropdown-container"
              >
                <div className="client-bp-dropdown-header">
                  {/* <RiHomeGearLine className="inline-block " size={30} /> */}
                  Business Process
                </div>
                <div className="bp-dropdown-box">
                  {data?.business_process?.map((item) => (
                    <div
                      key={item.id}
                      className="nav-dropdown-content cursor-pointer"
                      onClick={() => {
                        dispatch(pid(item.id));
                        dispatch(name(item.name));
                      }}
                    >
                      {capitalizeFirstLetter(item?.name)}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </li>
        </ul>
      </div>

      <ul>
        {ClientNavbarItems.map((item) => (
          <li
            key={item.id}
            className={
              activeLink === item.id ? "active nav-content" : "nav-content"
            }
            onMouseLeave={() => {
              setActiveDropdown(null);
              setActiveSecondaryDropdown(null);
            }}
          >
            <Link
              to={item.link}
              onClick={() => {
                if (item.dropdown) handleDropdown(item.id);
                setActiveLink(item.id);
              }}
            >
              {item.name}
              {item.dropdown && <RiArrowDownSFill className="dropdown-icon" />}
            </Link>

            {item.dropdown && activeDropdown === item.id && (
              <div className="nav-dropdown-container">
                {item.dropdown.map((subItem) =>
                  subItem.name !== hasAdminPermission || hasAdminPermission ? (
                    <div key={subItem.name}>
                      <Link
                        to={subItem.link}
                        className={
                          activeSubLink === subItem.name
                            ? "nav-dropdown-content active"
                            : "nav-dropdown-content"
                        }
                        onClick={() => {
                          if (subItem.secondaryDropdown) {
                            handleSecondaryDropdown(subItem.name);
                          }
                          setActiveSubLink(subItem.name);
                        }}
                      >
                        {subItem.name}
                        {subItem.secondaryDropdown && (
                          <RiArrowRightDownFill className="dropdown-icon" />
                        )}
                      </Link>

                      {subItem.secondaryDropdown &&
                        activeSecondaryDropdown === subItem.name && (
                          <div
                            className="nav-secondary-dropdown-container"
                            onMouseLeave={() => {
                              setActiveSecondaryDropdown(null);
                            }}
                          >
                            {subItem.secondaryDropdown.map((secondaryItem) => (
                              <div>
                                <Link
                                  key={secondaryItem.name}
                                  to={secondaryItem.link}
                                  className={
                                    activeSubLink === secondaryItem.name
                                      ? "nav-dropdown-secondary-content active"
                                      : "nav-dropdown-secondary-content"
                                  }
                                >
                                  {secondaryItem.name}
                                </Link>
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                  ) : null
                )}
              </div>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default ClientNavbar;