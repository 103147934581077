import React, { useEffect, useState, useMemo, useCallback } from "react";
import CustomTable from "../../shared/CustomTable.js";
import SearchInput from "../../shared/SearchInput/SearchInput.js";
import ModalHandler from "./ModalHandlercopy.js";
import ModalFileUpload from "../../shared/ModalFileUpload.js";
import LoadingComponent from "../../shared/LoadingDropDownSelection/LoadingComponent.js";
import { toast } from "react-hot-toast";
import swal from "sweetalert";
import { useMutation, useQuery } from "@apollo/client";
import { downloadExcelData } from "../../utils/ExportExcelUtility.js";
import { GET_CLIENT_BIA, DELETE_CLIENT_ASSET, GET_BUSINESS_PROCESS } from "../../graphql/client/query.js";
import AddIcon from "@mui/icons-material/Add";
import { GET_ASSET_LIST } from "../../graphql/superAdmin/query/controlConfiguration/query.js";

const ClientBusinessImpactAnalysis = () => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);
  const [mode, setMode] = useState("add");
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedBusinessProcessId, setSelectedBusinessProcessId] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [id, setId] = useState();

  const pageSize = 10;
  const localStorageData = useMemo(() => JSON.parse(localStorage.getItem("cyber-minds")), []);
  const clientID = localStorageData?.user?.client?.id;

  const { data: businessProcesses, loading: businessLoading } = useQuery(GET_BUSINESS_PROCESS, { variables: { limit: 100 } });
  const { data: biaClient, refetch: fetchData } = useQuery(GET_CLIENT_BIA, { variables: { limit: 1000 } });

  const [deleteClientAssetMutation] = useMutation(DELETE_CLIENT_ASSET);

  const filteredData = useMemo(() => {
    if (!biaClient?.client_asset) return [];
    return biaClient.client_asset.filter(item =>
      Object.keys(item).some(key => item[key]?.toString().toLowerCase().includes(searchValue.toLowerCase()))
    );
  }, [biaClient, searchValue]);

  const handleSelectionChange = useCallback((event) => {
    setSelectedBusinessProcessId(event.target.value);
  }, []);

  const handleAddClick = useCallback(() => {
    if (selectedBusinessProcessId) {
      setSelectedItem(null);
      setMode("add");
      setIsModalOpen(true);
    } else {
      toast.error("Please select a business process.");
    }
  }, [selectedBusinessProcessId]);

  const handleEdit = useCallback((id, item) => {
    setSelectedItem(item);
    setId(id);
    setMode("edit");
    setIsModalOpen(true);
  }, []);

  const handleDelete = useCallback(async (id) => {
    try {
      const response = await deleteClientAssetMutation({ variables: { id: parseInt(id, 10) } });
      if (response.data.delete_client_asset.affected_rows > 0) {
        toast.success("Asset deleted successfully.");
        // fetchData();
      } else {
        toast.error("No asset found with the provided ID.");
      }
    } catch (error) {
      console.error("Error deleting asset:", error);
      toast.error("Failed to delete the asset.");
    }
  }, [deleteClientAssetMutation, fetchData]);

  return (
    <div className="main-container">
      <div className="table-title">
        <div className="flex justify-between items-center space-x-4">
          <span>Business Impact Analysis</span>
          <div className="w-56">
            <select
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5"
              onChange={handleSelectionChange}
              value={selectedBusinessProcessId || ""}
            >
              <option value="">Select Business Process</option>
              {businessProcesses?.business_process?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="flex justify-between items-center space-x-4">
          <SearchInput searchValue={searchValue} setSearchValue={setSearchValue} />
          <button onClick={() => downloadExcelData(filteredData, "Business Impact Analysis")} className="btn_file_download">
            Export to Excel
          </button>
          <button onClick={handleAddClick} className="btn-add-new">
            <AddIcon />
          </button>
        </div>
      </div>
      {fetchLoading || businessLoading ? (
        <LoadingComponent />
      ) : !selectedBusinessProcessId ? (
        <div className="alert-no-data" role="alert">
          Select a business process
        </div>
      ) : (
        <CustomTable
          filteredData={filteredData}
          onEditClick={handleEdit}
          onDeleteClick={handleDelete}
          dataPerPage={pageSize}
          totalItems={filteredData.length}
          passedExcludeColumns={['__typename']}
        />
      )}
      <ModalHandler
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        mode={mode}
        selectedItemId={id}
        selectedItem={selectedItem}
        selectedClientId={clientID}
        selectedBusinessProcessId={selectedBusinessProcessId}
        fetchData={fetchData}
      />
      <ModalFileUpload
        isOpen={isModalUploadOpen}
        onClose={() => setIsModalUploadOpen(false)}
        selectedClientId={clientID}
        selectedBusinessProcessId={selectedBusinessProcessId}
      />
    </div>
  );
};

export default React.memo(ClientBusinessImpactAnalysis);
