import React, { useState, useEffect } from "react";
import http from "../../../resources/http";
import Modal from "../../../shared/Modal";
import Form from "../../../shared/Form";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import swal from "sweetalert";
import { ADD_CONTROL_EFFECTIVENESS, UPDATE_CONTROL_EFFECTIVENESS } from "../../../graphql/superAdmin/mutation/controlConfiguration/mutation";
import { ApolloError, useMutation } from "@apollo/client";
import toast from "react-hot-toast";

const ModalHandler = ({
	data,
	isOpen,
	mode,
	onClose,
	header,
	selectedItem,
	selectedItemId,
	size,
	url_create,
	url_by_pk,
	fetchData,
}) => {
	const initialState = {
		control_type: "",
		control_domain: "",
		control_category: "",
		effectiveness_in_percent: "",
	};
	const [formData, setFormData] = useState(initialState);
	const [errors, setErrors] = useState({});
	const [fetchLoading, setFetchLoading] = useState(false);

	// Validations for inputs
	const validations = {
		control_type: (value) => (value ? "" : "This field is required."),
		control_domain: (value) => (value ? "" : "This field is required."),
		control_category: (value) => (value ? "" : "This field is required."),
		effectiveness_in_percent: (value) =>
			value ? "" : "This field is required.",
	};

	// Handle change for input fields
	const handleChange = (key, value) => {
		setFormData((prevState) => ({ ...prevState, [key]: value }));
		if (validations[key]) {
			const error = validations[key](value);
			setErrors((prevState) => ({ ...prevState, [key]: error }));
		}
	};

	// Reset form data
	const resetForm = () => {
		setFormData(initialState);
		setErrors({});
	};

	useEffect(() => {
		if (mode === "edit" && selectedItem) {
			setFormData({
				control_type: selectedItem.control_type,
				control_domain: selectedItem.control_domain,
				control_category: selectedItem.control_category,
				effectiveness_in_percent: selectedItem.effectiveness_in_percent,
			});
		} else {
			resetForm();
		}
	}, [mode, selectedItem]);
	const [createMutation] = useMutation(ADD_CONTROL_EFFECTIVENESS);
	const [updateMutation] = useMutation(UPDATE_CONTROL_EFFECTIVENESS);
	// const [del] = useMutation(DELETE_CONTROL_TYPE);
	const handleSubmit = async () => {
		let newErrors = {};
		Object.keys(formData).forEach((key) => {
			if (validations[key]) newErrors[key] = validations[key](formData[key]);
		});
		setErrors(newErrors);
		try {
			let response;

			if (mode === 'edit') {
				response = await updateMutation({
					variables: {
						id: parseInt(selectedItemId),
						input: {
							control_type_pk: parseInt(formData.control_type),
							control_domain_pk: parseInt(formData.control_domain),
							control_category_pk: parseInt(formData.control_category),
							effectiveness_in_percent: parseInt(formData.effectiveness_in_percent),
						},
					},
				});
			} else {
				response = await createMutation({
					variables: {
						inputs: {
							created_at: new Date().toISOString().split('T')[0],
							control_type: parseInt(formData.control_type),
							control_domain: parseInt(formData.control_domain),
							control_category: parseInt(formData.control_category),
							effectiveness_in_percent: parseInt(formData.effectiveness_in_percent),
						},
					},
				});
			}

			const dynamicKey = Object.keys(response.data)[0]; // Get the dynamic key

			if (response.data[dynamicKey].affected_rows > 0) {
				toast.success(
					mode === 'edit'
						? 'It has been updated successfully'
						: 'It has been added successfully'
				);
				resetForm();
				// fetchData();
				onClose();
			}
		} catch (error) {
			if (error instanceof ApolloError) {
				const errorMessage = error.message.includes('duplicate key value')
					? 'Control  label already exists. Please choose a different one.'
					: 'An error occurred while processing your request.';
				toast.error(errorMessage);
			} else {
				console.error('Unexpected error:', error); // Log unexpected errors
				toast.error('An unexpected error occurred.');
			}
		} finally {
			setFetchLoading(false);
		}
	};

	const handleError = (error) => {
		setFetchLoading(false);
		if (error?.response?.data) {
			let newErrors = {};
			Object.entries(error.response.data).forEach(([key, errorMessage]) => {
				if (formData[key]) {
					newErrors[key] = errorMessage[0];
				}
			});
			setErrors(newErrors);
		} else {
			swal("Error", "Something went wrong, please try again", "error");
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size={size}>
			<Modal.Header>
				<Modal.Title>
					{mode === "edit" ? `Update ${header}` : `Add New ${header}`}
				</Modal.Title>
			</Modal.Header>
			{fetchLoading ? (
				<LoadingComponent />
			) : (
				<Modal.Body>
					<div className="modal-two-columns">
						<Form>
							{/* Control Type */}
							<Form.Group>
								<Form.Label>Control Type</Form.Label>
								<select
									value={formData.control_type}
									onChange={(e) => handleChange("control_type", e.target.value)}
								>
									<option value="" disabled>
										Select...
									</option>
									{data?.control_type?.map((type) => (
										<option key={type.id} value={type.id}>
											{type.label}
										</option>
									))}
								</select>
								{errors.control_type && (
									<Form.Control.Feedback type="invalid">
										{errors.control_type}
									</Form.Control.Feedback>
								)}
							</Form.Group>

							{/* Control Domain */}
							<Form.Group>
								<Form.Label>Control Domain</Form.Label>
								<select
									value={formData.control_domain}
									onChange={(e) =>
										handleChange("control_domain", e.target.value)
									}
								>
									<option value="" disabled>
										Select...
									</option>
									{data?.control_domain?.map((domain) => (
										<option key={domain.id} value={domain.id}>
											{domain.label}
										</option>
									))}
								</select>
								{errors.control_domain && (
									<Form.Control.Feedback type="invalid">
										{errors.control_domain}
									</Form.Control.Feedback>
								)}
							</Form.Group>

							{/* Control Category */}
							<Form.Group>
								<Form.Label>Control Category</Form.Label>
								<select
									value={formData.control_category}
									onChange={(e) =>
										handleChange("control_category", e.target.value)
									}
								>
									<option value="" disabled>
										Select...
									</option>
									{data?.control_category?.length > 0 ? (
										data?.control_category?.map((category) => (
											<option key={category.id} value={category.id}>
												{category.label}
											</option>
										))
									) : (
										<option disabled>No categories available</option>
									)}
								</select>
								{errors.control_category && (
									<Form.Control.Feedback type="invalid">
										{errors.control_category}
									</Form.Control.Feedback>
								)}
							</Form.Group>

							{/* Effectiveness in percent */}
							<Form.Group>
								<Form.Label>Effectiveness in percent</Form.Label>
								<input
									type="number"
									min="0"
									max="100"
									placeholder="Enter effectiveness in percent"
									value={formData.effectiveness_in_percent}
									onChange={(e) =>
										handleChange("effectiveness_in_percent", e.target.value)
									}
								/>
								{errors.effectiveness_in_percent && (
									<Form.Control.Feedback type="invalid">
										{errors.effectiveness_in_percent}
									</Form.Control.Feedback>
								)}
							</Form.Group>
						</Form>
					</div>
				</Modal.Body>
			)}

			<Modal.Footer>
				<button
					className="btn-cancel"
					onClick={() => {
						onClose();
						resetForm();
					}}
				>
					Cancel
				</button>
				<button className="btn-add-new" onClick={handleSubmit}>
					{mode === "edit" ? "Save Changes" : "Add"}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalHandler;
