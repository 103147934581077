import React, { useState, useEffect } from "react";
import http from "../../../resources/http";
import Modal from "../../../shared/Modal";
import Form from "../../../shared/Form";
import {
	THREAT_CATEGORIES,
	THREAT_VECTORS_LIST,
} from "../../../api/threatCatalog";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import swal from "sweetalert";

const ModalHandler = ({
	isOpen,
	mode,
	onClose,
	header,
	selectedItem,
	selectedItemId,
	size,
	url_get,
	url_by_pk,
	fetchData,
}) => {
	const [fetchLoading, setFetchLoading] = useState(false);
	const [threatVectorsDropdownOptionsData, setThreatVectorsDropdownOptions] =
		useState([]);
	const [threatCategoryDropdownOptions, setThreatCategoryDropdownOptions] =
		useState([]);

	const pathDropdownOptions = ["Entry", "Middle", "End"];
	async function fetchFormOptionData() {
		try {
			const listOfThreatVectors = await http.get(THREAT_VECTORS_LIST);
			const listOfThreatCategory = await http.get(THREAT_CATEGORIES);

			const threatVectorsOptionsData = listOfThreatVectors.data?.map(
				(item) => item.name
			);
			setThreatVectorsDropdownOptions(threatVectorsOptionsData);
			const threatCategoryOptions = listOfThreatCategory.data?.map(
				(item) => item.name
			);
			setThreatCategoryDropdownOptions(threatCategoryOptions);
		} catch (error) {
			console.log("error occurred while fetching form dropdown options.");
		}
	}
	useEffect(() => {
		if (isOpen) {
			fetchFormOptionData();
		}
	}, [isOpen]);

	const initialState = {
		control_identifier: "",
		control_name: "",
		discussion: "",
		related_controls: "",
	};
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({ initialState });
	const [errors, setErrors] = useState({});

	const validations = {
		control_identifier: (value) => (value ? "" : "This field is required."),
		control_name: (value) => (value ? "" : "This field is required."),
		related_controls: (value) => (value ? "" : "This field is required."),
	};

	const handleChange = (key, value) => {
		setFormData((prevState) => ({ ...prevState, [key]: value }));
		if (validations[key]) {
			const error = validations[key](value);
			setErrors((prevState) => ({ ...prevState, [key]: error }));
		}
	};

	const resetForm = () => {
		setFormData(initialState);
		setErrors({});
	};

	useEffect(() => {
		if (mode === "edit" && selectedItem) {
			console.log(selectedItem, mode, "selectedItem");
			setLoading(true);
			setFormData(selectedItem);
			setLoading(false);
		} else {
			resetForm();
		}
	}, [mode, selectedItem]);

	const formFieldsConfig = {
		control_name: {
			label: "Control Name",
			type: "text",
			placeholder: "Text...",
			value: formData?.control_name,
			onChange: (e) => handleChange("control_name", e.target.value),
			error: errors.control_name,
		},

		control_identifier: {
			label: "Control Identifier",
			type: "text",
			placeholder: "Text...",
			value: formData?.control_identifier,
			onChange: (e) => handleChange("control_identifier", e.target.value),
			error: errors.control_identifier,
		},
		related_controls: {
			label: "Related Controls",
			type: "text",
			placeholder: "Text...",
			value: formData?.related_controls,
			onChange: (e) => handleChange("related_controls", e.target.value),
			error: errors.related_controls,
		},

		discussion: {
			label: "Discussion",
			type: "textarea",
			maxLength: 400,
			placeholder: "Text...",
			value: formData?.discussion,
			onChange: (e) => handleChange("discussion", e.target.value),
			error: errors.discussion,
		},
	};

	const handleSubmit = () => {
		let newErrors = {};
		Object.entries(formFieldsConfig).forEach(([key, config]) => {
			if (validations[key]) newErrors[key] = validations[key](formData[key]);
		});
		setErrors(newErrors);
		if (Object.values(newErrors).every((error) => !error)) {
			// console.log("Submitting form data:", formData);
			if (mode === "edit") {
				http
					.patch(`${url_by_pk}${selectedItemId}`, formData)
					.then((res) => {
						// fetchData();
						onClose();
						swal("Success!", "It has been updated successfully", "success");
						resetForm();
					})
					.catch((error) => {
						setFetchLoading(false);
						if (error && error.response && error.response.data) {
							for (const [key, errorMessage] of Object.entries(
								error.response.data
							)) {
								if (formFieldsConfig[key]) {
									newErrors[key] = errorMessage[0];
								}
								setErrors(newErrors);
							}
						} else {
							swal("Error", "Something went wrong, please try again", "error");
						}
					});
			} else {
				http
					.post(`${url_get}`, formData)
					.then((res) => {
						// fetchData();
						onClose();
						swal("Success!", "It has been updated successfully", "success");
						resetForm();
					})
					.catch((error) => {
						setFetchLoading(false);
						if (error && error.response && error.response.data) {
							for (const [key, errorMessage] of Object.entries(
								error.response.data
							)) {
								if (formFieldsConfig[key]) {
									newErrors[key] = errorMessage[0];
								}
								setErrors(newErrors);
							}
						} else {
							swal("Error", "Something went wrong, please try again", "error");
						}
					});
			}
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size={size}>
			<Modal.Header>
				<Modal.Title>
					{mode === "edit" ? `Update  ${header}` : `Add New  ${header}`}
				</Modal.Title>
			</Modal.Header>
			{fetchLoading ? (
				<LoadingComponent />
			) : (
				<Modal.Body>
					<div className="modal-two-columns">
						<Form>
							{Object.entries(formFieldsConfig || {}).map(
								([key, fieldConfig]) => (
									<Form.Group key={key}>
										<Form.Label>{fieldConfig.label}</Form.Label>
										<Form.Control {...fieldConfig} />
										{errors[key] && (
											<Form.Control.Feedback type="invalid">
												{errors[key]}
											</Form.Control.Feedback>
										)}
									</Form.Group>
								)
							)}
						</Form>
					</div>
				</Modal.Body>
			)}
			<Modal.Footer>
				<button
					className="btn-cancel"
					onClick={() => {
						onClose();
						resetForm();
					}}
				>
					Cancel
				</button>
				<button className="btn-add-new" onClick={handleSubmit}>
					{mode === "edit" ? "Save Changes" : "Add"}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalHandler;
