import React, { useEffect, useState } from "react";
import CustomTable from "../../../shared/CustomTable";
import http from "../../../resources/http";
import {
	RESPONSE_DISTRIBUTION_LIST,
	RESPONSE_DISTRIBUTION_BY_PK,
} from "../../../api/excelUpload";
import SearchInput from "../../../shared/SearchInput/SearchInput";
import SearchHandler from "../../../shared/SearchHandler";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import AddIcon from "@mui/icons-material/Add";
import swal from "sweetalert";
import ModalHandler from "./ModalHandler";

const ViewResponseDistribution = () => {
	const UploadUrl = "/excel-upload/upload-response-distribution-excel/";
	const url_get = RESPONSE_DISTRIBUTION_LIST;
	const url_by_pk = RESPONSE_DISTRIBUTION_BY_PK;

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [mode, setMode] = useState("add"); // 'add' or 'edit'
	const [selectedItem, setSelectedItem] = useState(null);
	const [id, setId] = useState();
	const [data, setData] = useState([]);
	const [fetchLoading, setFetchLoading] = useState(false);

	// search
	const [searchValue, setSearchValue] = useState("");
	const fieldsToFilterBy = [""];
	const debounceDelay = 400;

	//data
	async function fetchData() {
		setFetchLoading(true);
		try {
			const response = await http.get(url_get);
			setData(response.data);
		} catch (error) {
			console.error("Error fetching data:", error);
		} finally {
			setFetchLoading(false);
		}
	}
	// useEffect(() => {
	// 	          // fetchData();
	// }, []);

	const filteredData = SearchHandler(data, searchValue, fieldsToFilterBy);

	const handleInputChange = (newValue) => {
		setSearchValue(newValue);
	};

	console.log(JSON.stringify(data));
	// Handle the edit click from the table
	const handleEdit = (id, item) => {
		setSelectedItem(item);
		setId(id);
		setMode("edit");
		setIsModalOpen(true);
	};

	const handleDelete = async (id) => {
		setFetchLoading(true);
		try {
			await http.delete(`${url_by_pk}${id}`);
			// fetchData();
			setFetchLoading(false);
			swal("Success", "It has been deleted successfully", "success");
		} catch (error) {
			swal("Failed", "Failed to delete item", "error");
			setFetchLoading(false);
			console.error("Failed to delete item:", error);
		}
	};
	// Check if there are distinct "incident" and "breach" records
	const hasIncident = data?.some((record) => record.attack_type === "incident");
	const hasBreach = data?.some((record) => record.attack_type === "breach");

	// Create a boolean variable indicating if both incident and breach are present
	const hasBoth = hasIncident && hasBreach;
	return (
		<main>
			<div className="main-container">
				<div className="table-title ">
					<span className="font-bold ">Response Distribution</span>
					<div className="flex justify-between items-center space-x-4">
						<SearchInput
							searchValue={searchValue}
							setSearchValue={handleInputChange}
						/>
						{!hasBoth ? (
							<button
								className="btn-add-new"
								onClick={() => {
									setSelectedItem(null);
									setMode("add");
									setIsModalOpen(true);
								}}
							>
								<AddIcon />
							</button>
						) : null}
					</div>
				</div>
				{/* table */}
				{fetchLoading ? (
					<LoadingComponent />
				) : searchValue.length > 0 && filteredData.length === 0 ? (
					<div className="alert-no-data">
						No data available for
						<span className="search-value">{searchValue}</span>
						at the moment.
					</div>
				) : (
					<CustomTable
						filteredData={filteredData}
						onEditClick={handleEdit}
						onDeleteClick={handleDelete}
					/>
				)}
				{/* add and edit modal */}
				<ModalHandler
					isOpen={isModalOpen}
					onClose={() => setIsModalOpen(false)}
					mode={mode}
					size="xl"
					selectedItemId={id}
					selectedItem={selectedItem}
					header="Availability"
					url_create={url_get}
					url_by_pk={url_by_pk}
					fetchData={fetchData}
				/>
			</div>
		</main>
	);
};

export default ViewResponseDistribution;
