import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import LoadingSpinner from "../../../../utils/LoadingSpinner";
import { useQuery } from "@apollo/client";
import { GET_VELNERABILITY_SUMMARY } from "../../../../graphql/client/query";

const VendersDetail = ({ textMe }) => {
  const selectedVendor = useSelector((state) => state.vendor);
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const cpeValue = useSelector((state) => state.cpe);
  const selectedProduct = useSelector((state) => state.vendor);
  const processId = useSelector((state) => state.pid);
  let type = JSON.parse(localStorage.getItem("cyber-minds"));
  let clientID = type?.user?.client;
  const [pageNumber, setPageNumber] = useState(0);
  console.log(selectedProduct, "selectedProduct");

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const [parsedData, setParsedData] = useState([]);
  const [severityCounts, setSeverityCounts] = useState({});

  console.log(severityCounts, "severityCounts");
  //Detail properties to displied for every product
  const {
    data: cpeData,
    loading,
    error,
    refetch,
  } = useQuery(GET_VELNERABILITY_SUMMARY, {
    variables: { cpe: cpeValue },
  });

  // const DisplayCpeData = cpeData?.cpe_summary?.data
  //   ?.filter((vendors) => vendors?.product === selectedProduct)
  //   .map((risk) => {
  //     return (
  //       <tr>
  //         <td className="product_color">{risk.product}</td>
  //         <td className="critical_color">{critical.length}</td>
  //         <td className="high_color">{high.length}</td>
  //         <td className="medium_color">{medium.length}</td>
  //         <td className="low_color">{low.length}</td>
  //       </tr>
  //     );
  //   });

  console.log(cpeData, "cpe");
  // Function to group and count occurrences of baseSeverity
  const calculateSeverityCounts = (data) => {
    const counts = {};
    data.forEach((vendor) => {
      const severity = vendor?.metrics?.cvssMetricV2?.[0]?.baseSeverity || "UNKNOWN";
      counts[severity] = (counts[severity] || 0) + 1;
    });
    return counts;
  };
  // Parse the JSON string safely
  useEffect(() => {
    if (cpeData?.cpe_summary?.data) {
      try {
        const rawData = cpeData.cpe_summary.data;
        if (typeof rawData === "string") {
          const parsed = JSON.parse(rawData);
          setParsedData(parsed);
          const counts = calculateSeverityCounts(parsed);
          setSeverityCounts(counts);
        } else {
          setParsedData([]);
        }
      } catch (error) {
        console.error("Failed to parse data:", error);
        setParsedData([]); // Ensure the state is reset in case of error
      }
    }
  }, [cpeData]);

  console.log(parsedData, "json");
  const DisplayData = parsedData
    ?.slice(pagesVisited, pagesVisited + usersPerPage)
    ?.map((vendor) => {
      return (
        <tr key={vendor?.id}>
          <td className="color_id font-semibold">{vendor?.id}</td>
          <td className="table_width">{vendor?.descriptions?.[0]?.value}</td>
          <td>{vendor?.metrics?.cvssMetricV2?.[0]?.exploitabilityScore}</td>
          <td>{vendor?.metrics?.cvssMetricV2?.[0]?.baseSeverity}</td>
          <td>{vendor?.metrics?.cvssMetricV2?.[0]?.impactScore}</td>
        </tr>
      );
    });
  // const DisplayData = cpeData?.cpe_summary?.data
  //   ?.slice(pagesVisited, pagesVisited + usersPerPage)
  //   ?.map((vender) => {
  //     return (
  //       <tr>
  //         <td className="color_id font-semibold">{vender?.id}</td>
  //         <td className="table_width">{vender?.descriptions?.[0]?.value}</td>
  //         <td>{vender?.metrics?.cvssMetricV2?.[0]?.exploitabilityScore}</td>
  //         <td>{vender?.metrics?.cvssMetricV2?.[0]?.baseSeverity}</td>
  //         <td>{vender?.metrics?.cvssMetricV2?.[0]?.impactScore}</td>
  //       </tr>
  //     );
  //   });
  console.log(data, "critical");
  const pageCount = Math.ceil(data?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };


  return (
    <div>
      {parsedData?.length ? (
        <div className="z-100  flex flex-col space-y-10 justify-center relative p-10 items-center">
          <div className='bg-gray-100 w-full p-4'>
            <p className=' text-2xl mb-2'>Vulnerability Heat Map</p>
            <div className="grid grid-cols-5 md:grid-cols-2 lg:grid-cols-5  gap-6">
              <div
                className=" bg-white shadow-md rounded-lg p-4 flex items-center space-x-4 hover:shadow-lg transition"
              >
                <div>
                  <p className="text-gray-400">PRODUCT</p>
                  <h2 className="text-2xl font-semibold text-gray-800">{selectedProduct}</h2>
                </div>
              </div>
              <div
                className="critical_color shadow-md rounded-lg p-4 flex items-center space-x-4 hover:shadow-lg transition"
              >
                <div>
                  <p className="text-gray-400">CRITICAL</p>
                  <h2 className="text-3xl font-semibold text-white">{severityCounts?.CRITICAL ?? 0}</h2>
                </div>
              </div>
              <div
                className="high_color shadow-md rounded-lg p-4 flex items-center space-x-4 hover:shadow-lg transition"
              >
                <div>
                  <p className="text-gray-600">HIGH</p>
                  <h2 className="text-3xl font-semibold text-gray-800">{severityCounts?.HIGH ?? 0}
                  </h2>
                </div>
              </div>
              <div
                className="medium_color shadow-md rounded-lg p-4 flex items-center space-x-4 hover:shadow-lg transition"
              >
                <div>
                  <p className="text-gray-500">MEDIUM</p>
                  <h2 className="text-3xl font-semibold text-gray-800">{severityCounts?.MEDIUM ?? 0}
                  </h2>
                </div>
              </div>
              <div
                className="bg-green-600 shadow-md rounded-lg p-4 flex items-center space-x-4 hover:shadow-lg transition"
              >
                <div className="">
                  <p className="text-gray-200">LOW</p>
                  <h2 className="text-3xl font-semibold text-white">{severityCounts?.LOW ?? 0}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="w-96 sr-only">
            <div className="rounded overflow-hidden h-full shadow-md flex flex-col  justify-center items-center">
              <div className="flex flex-col justify-center items-center space-y-4 w-full">
                <div className="flex space-x-4">
                  <h3 className="text-4xl font-semibold">
                    Vulnerability Heat Map
                  </h3>
                </div>
                <table className="text-black tables h-full">
                  <thead>
                    <tr>
                      <th className="product_color">Product</th>
                      <th className="critical_color">Critical</th>
                      <th className="high_color">High</th>
                      <th className="medium_color">Medium</th>
                      <th className="low_color">Low</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="product_color">{selectedVendor}</td>
                      <td className="critical_color">{severityCounts?.CRITICAL ?? 0}</td>
                      <td className="high_color">{severityCounts?.HIGH ?? 0}</td>
                      <td className="medium_color">{severityCounts?.MEDIUM ?? 0}</td>
                      <td className="low_color">{severityCounts?.LOW ?? 0}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="text-black ">
            <div className="rounded overflow-hidden flex  justify-center items-center">
              <table class="tables">
                <thead>
                  <tr>
                    <th>Vuln ID</th>
                    <th>Description Data</th>
                    <th>Exploitability Score</th>
                    <th>Severity</th>
                    <th>Impact Score</th>
                  </tr>
                </thead>
                <tbody>{DisplayData}</tbody>
              </table>
            </div>

            <div className="w-full">
              {data?.length > 10 && (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={"paginationBttns"}
                  previousLinkClassName={"previousBttn"}
                  nextLinkClassName={"nextBttn"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        // <div>
        //   <VulnerabilityCards vulnerabilities={data} />
        // </div>
        <LoadingSpinner />
      )}
    </div>
  );
};

export default VendersDetail;
