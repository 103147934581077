import React, { useState, useEffect } from 'react';
import http from '../../../resources/http';
import Modal from '../../../shared/Modal';
import Form from '../../../shared/Form';
import {
  CONTROL_DOMAIN,
  CONTROL_CATEGORY,
  CONTROL_APPLICABILITY,
  INDUSTRY_USECASE,
  INDUSTRY_STANDARD,
  CONTROL_IMPACT,
  CONTROL_SCOPE,
  CONTROL_TYPE,
  THREAT_VECTORS,
} from '../../../api/configurations';
import LoadingComponent from '../../../shared/LoadingDropDownSelection/LoadingComponent';
import swal from 'sweetalert';

const ModalHandler = ({
  isOpen,
  mode,
  onClose,
  header,
  selectedItem,
  selectedItemId,
  size,
  url_create,
  url_by_pk,
  fetchData,
}) => {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [domainsDropdownOptions, setDomainsDropdownOptions] = useState([]);
  const [categoryDropdownOptions, setCategoryDropdownOptions] = useState([]);

  const [scopeDropdownOptions, setScopeDropdownOptions] = useState([]);
  const [impactDropdownOptions, setImpactDropdownOptions] = useState([]);
  const [typeDropdownOptions, setTypeDropdownOptions] = useState([]);
  const [standardDropdownOptions, setStandardDropdownOptions] = useState([]);
  const [applicabilityDropdownOptions, setApplicabilityDropdownOptions] =
    useState([]);
  const [usecaseDropdownOptions, setUsecaseDropdownOptions] = useState([]);
  const [threatDropdownOptions, setThreatDropdownOptions] = useState([]);
  console.log(usecaseDropdownOptions, 'usecaseDropdownOptions');
  async function fetchFormOptionData() {
    try {
      const listOfDomains = await http.get(CONTROL_DOMAIN);
      const listOfCategory = await http.get(CONTROL_CATEGORY);
      const listOfScope = await http.get(CONTROL_SCOPE);
      const listOfImpact = await http.get(CONTROL_IMPACT);
      const listOfType = await http.get(CONTROL_TYPE);
      const listOfStandard = await http.get(INDUSTRY_STANDARD);
      const listOfApplicability = await http.get(CONTROL_APPLICABILITY);
      const listOfUsecase = await http.get(INDUSTRY_USECASE);
      const listOfThreat = await http.get(THREAT_VECTORS);

      const domainsOptionsData = listOfDomains.data?.map((item) => item.name);
      setDomainsDropdownOptions(domainsOptionsData);

      const categoryOptions = listOfCategory.data?.map((item) => item.title);
      setCategoryDropdownOptions(categoryOptions);

      const scopeOptions = listOfScope.data?.map((item) => item.control_scope);
      setScopeDropdownOptions(scopeOptions);

      const impactOptions = listOfImpact.data?.map(
        (item) => item.control_impact
      );
      setImpactDropdownOptions(impactOptions);

      const typeOptions = listOfType.data?.map((item) => item.control_type);
      setTypeDropdownOptions(typeOptions);

      const standardOptions = listOfStandard.data?.map((item) => item.title);
      setStandardDropdownOptions(standardOptions);

      const applicabilityOptions = listOfApplicability.data?.map(
        (item) => item.control_applicability
      );
      setApplicabilityDropdownOptions(applicabilityOptions);

      const usecaseOptions = listOfUsecase.data?.map((item) => item.title);
      setUsecaseDropdownOptions(usecaseOptions);

      const threatOptions = listOfThreat.data?.map((item) => item.name);
      setThreatDropdownOptions(threatOptions);
    } catch (error) {
      console.log('error occurred while fetching form dropdown options.');
    }
  }
  useEffect(() => {
    if (isOpen) {
      fetchFormOptionData();
    }
  }, [isOpen]);

  const initialState = {
    control_name: '',
    control_domain: '',
    control_category: '',
    control_scope: '',
    control_impact: '',
    control_type: '',
    control_applicability: '',
    primary_threat: '',
    secondary_threat: [],
    industry_usecase: [],
    industry_standard_text: [],
    control_description: '',
    cmc_name: '',
    control_efficacy: '',
    example_threat: '',
    industry_identifier: '',
    iso_nist: '',
    mitre: '',
    number_of_threats_solved: '',
    owasp: '',
    rationalized_threats: '',
    relevance: '',
    stride: '',
  };
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ initialState });
  const [errors, setErrors] = useState({});
  console.log(formData, 'formdata');
  const validations = {
    control_domain: (value) => (value ? '' : 'This field is required.'),
    control_category: (value) => (value ? '' : 'This field is required.'),
    control_name: (value) => (value ? '' : 'This field is required.'),
  };

  const handleChange = (key, value) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
    if (validations[key]) {
      const error = validations[key](value);
      setErrors((prevState) => ({ ...prevState, [key]: error }));
    }
  };

  const resetForm = () => {
    setFormData(initialState);
    setErrors({});
  };

  useEffect(() => {
    if (mode === 'edit' && selectedItem) {
      console.log(selectedItem, mode, 'selectedItem');
      setLoading(true);
      setFormData(selectedItem);
      setLoading(false);
    } else {
      resetForm();
    }
  }, [mode, selectedItem]);

  const formFieldsConfig = {
    control_name: {
      label: 'Control Name',
      type: 'text',
      placeholder: 'text...',
      value: formData?.control_name,
      onChange: (e) => handleChange('control_name', e.target.value),
      error: errors.control_name,
      required: true,
    },
    control_domain: {
      label: 'Control Domain',
      type: 'select',
      placeholder: 'Select...',
      options: domainsDropdownOptions,
      value: formData.control_domain || '',
      onChange: (e) => handleChange('control_domain', e.target.value),
      error: errors.control_domain,
      required: true,
    },
    control_category: {
      label: 'Control Category',
      type: 'select',
      placeholder: 'Select...',
      options: categoryDropdownOptions,
      value: formData.control_category || '',
      onChange: (e) => handleChange('control_category', e.target.value),
      error: errors.control_category,
      required: true,
    },

    control_scope: {
      label: 'Control Scope',
      type: 'select',
      placeholder: 'Select...',
      options: scopeDropdownOptions,
      value: formData.control_scope || '',
      onChange: (e) => handleChange('control_scope', e.target.value),
      error: errors.control_scope,
    },

    control_impact: {
      label: 'Control Impact',
      type: 'select',
      placeholder: 'Select...',
      options: impactDropdownOptions,
      value: formData.control_impact || '',
      onChange: (e) => handleChange('control_impact', e.target.value),
      error: errors.control_impact,
    },
    control_type: {
      label: 'Control  Type',
      type: 'select',
      placeholder: 'Select...',
      options: typeDropdownOptions,
      value: formData.control_type || '',
      onChange: (e) => handleChange('control_type', e.target.value),
      error: errors.control_type,
    },
    control_applicability: {
      label: 'Control Applicability',
      type: 'select',
      placeholder: 'Select...',
      options: applicabilityDropdownOptions,
      value: formData.control_applicability || '',
      onChange: (e) => handleChange('control_applicability', e.target.value),
      error: errors.control_applicability,
    },
    primary_threat: {
      label: 'Primary Threat',
      type: 'select',
      placeholder: 'Select...',
      options: threatDropdownOptions,
      value: formData.primary_threat || '',
      onChange: (e) => handleChange('primary_threat', e.target.value),
      error: errors.primary_threat,
    },
    secondary_threat: {
      label: 'Secondary Threat',
      type: 'multi-select',
      options: threatDropdownOptions,
      value: formData.secondary_threat || [],
      onChange: (selectedValues) =>
        handleChange('secondary_threat', selectedValues),
      error: errors.secondary_threat,
    },

    industry_usecase: {
      label: 'Industry Usecase',
      type: 'multi-select',
      placeholder: 'Select...',
      options: usecaseDropdownOptions,
      value: formData.industry_usecase || [],
      onChange: (selectedValues) =>
        handleChange('industry_usecase', selectedValues),
      error: errors.industry_usecase,
    },

    industry_standard_text: {
      label: 'Industry Standard',
      type: 'multi-select',
      placeholder: 'Select...',
      options: standardDropdownOptions,
      value: formData.industry_standard_text || [],
      onChange: (selectedValues) =>
        handleChange('industry_standard_text', selectedValues),
      error: errors.industry_standard_text,
    },

    control_description: {
      label: 'Control Description',
      type: 'text',
      as: 'textarea',
      placeholder: 'text...',
      value: formData?.control_description,
      onChange: (e) => handleChange('control_description', e.target.value),
      error: errors.control_description,
    },

    cmc_name: {
      label: 'CMC Name',
      type: 'text',
      placeholder: 'text...',
      value: formData?.cmc_name,
      onChange: (e) => handleChange('cmc_name', e.target.value),
      error: errors.cmc_name,
    },
    control_efficacy: {
      label: 'Control Efficacy',
      type: 'text',
      placeholder: 'text...',
      value: formData?.control_efficacy,
      onChange: (e) => handleChange('control_efficacy', e.target.value),
      error: errors.control_efficacy,
    },
    example_threat: {
      label: 'Example Threat',
      type: 'text',
      placeholder: 'text...',
      value: formData?.example_threat,
      onChange: (e) => handleChange('example_threat', e.target.value),
      error: errors.example_threat,
    },
    industry_identifier: {
      label: 'Industry Identifier',
      type: 'text',
      placeholder: 'text...',
      value: formData?.industry_identifier,
      onChange: (e) => handleChange('industry_identifier', e.target.value),
      error: errors.industry_identifier,
    },
    iso_nist: {
      label: 'ISO/NIST',
      type: 'text',
      placeholder: 'text...',
      value: formData?.iso_nist,
      onChange: (e) => handleChange('iso_nist', e.target.value),
      error: errors.iso_nist,
    },
    mitre: {
      label: 'MITRE',
      type: 'text',
      placeholder: 'text...',
      value: formData?.mitre,
      onChange: (e) => handleChange('mitre', e.target.value),
      error: errors.mitre,
    },
    number_of_threats_solved: {
      label: 'Number of Threats Solved',
      type: 'text',
      placeholder: 'text...',
      value: formData?.number_of_threats_solved,
      onChange: (e) => handleChange('number_of_threats_solved', e.target.value),
      error: errors.number_of_threats_solved,
    },
    owasp: {
      label: 'OWASP',
      type: 'text',
      placeholder: 'text...',
      value: formData?.owasp,
      onChange: (e) => handleChange('owasp', e.target.value),
      error: errors.owasp,
    },
    rationalized_threats: {
      label: 'Rationalized Threats',
      type: 'text',
      placeholder: 'text...',
      value: formData?.rationalized_threats,
      onChange: (e) => handleChange('rationalized_threats', e.target.value),
      error: errors.rationalized_threats,
    },
    relevance: {
      label: 'Relevance',
      type: 'text',
      placeholder: 'text...',
      value: formData?.relevance,
      onChange: (e) => handleChange('relevance', e.target.value),
      error: errors.relevance,
    },
    stride: {
      label: 'STRIDE',
      type: 'text',
      placeholder: 'text...',
      value: formData?.stride,
      onChange: (e) => handleChange('stride', e.target.value),
      error: errors.stride,
    },
  };

  const handleSubmit = () => {
    let newErrors = {};
    Object.entries(formFieldsConfig).forEach(([key, config]) => {
      if (validations[key]) newErrors[key] = validations[key](formData[key]);
    });
    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => !error)) {
      console.log('Submitting form data:', formData);

      if (mode === 'edit') {
        http
          .patch(`${url_by_pk}/${selectedItemId}`, formData)
          .then((res) => {
            setFetchLoading(false);
            onClose();
            swal('Success!', 'It has been updated successfully', 'success');
            // fetchData();
            resetForm();
          })
          .catch((error) => {
            setFetchLoading(false);
            if (error && error.response && error.response.data) {
              for (const [key, errorMessage] of Object.entries(
                error.response.data
              )) {
                if (formFieldsConfig[key]) {
                  newErrors[key] = errorMessage[0];
                }
                setErrors(newErrors);
              }
            } else {
              swal('Error', error.message, 'error'); // Show the actual error message from the backend
            }
          });
      } else {
        http
          .post(`${url_create}`, formData)
          .then((res) => {
            setFetchLoading(false);
            onClose();
            swal('Success!', 'It has been added successfully', 'success');
            // fetchData();
            resetForm();
          })
          .catch((error) => {
            setFetchLoading(false);
            if (error && error.response && error.response.data) {
              for (const [key, errorMessage] of Object.entries(
                error.response.data
              )) {
                if (formFieldsConfig[key]) {
                  newErrors[key] = errorMessage[0];
                }
                setErrors(newErrors);
              }
            } else {
              swal('Error', error.message, 'error'); // Show the actual error message from the backend
            }
          });
      }
    }
  };


  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <Modal.Header>
        <Modal.Title>
          {mode === 'edit' ? `Update  ${header}` : `Add New  ${header}`}
        </Modal.Title>
      </Modal.Header>
      {fetchLoading ? (
        <LoadingComponent />
      ) : (
        <Modal.Body>
          <div className="modal-three-columns">
            <Form>
              {Object.entries(formFieldsConfig || {}).map(
                ([key, fieldConfig]) => (
                  <Form.Group key={key}>
                    <Form.Label>
                      {fieldConfig.label}{' '}
                      {fieldConfig.required && (
                        <span style={{ color: 'red' }}>*</span>
                      )}
                    </Form.Label>
                    <Form.Control {...fieldConfig} />
                    {errors[key] && (
                      <Form.Control.Feedback type="invalid">
                        {errors[key]}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                )
              )}
            </Form>
          </div>
        </Modal.Body>
      )}

      <Modal.Footer>
        <button
          className="btn-cancel"
          onClick={() => {
            onClose();
            resetForm();
          }}
        >
          Cancel
        </button>
        <button className="btn-add-new" onClick={handleSubmit}>
          {mode === 'edit' ? 'Save Changes' : 'Add'}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalHandler;
