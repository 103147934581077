import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useApi } from '../hooks/useApis';
import MoreButton from '../layout/components/MoreButton';
import { baseURL } from '../resources/apiClient';
import http from '../resources/http';
import {
  formatListWithComma,
  getControlCatalogIds,
  removeDuplicates,
} from '../utils/helpers';

import { CLIENT_CONTROLS_CATALOG_ENDPOINT } from '../api/threatCataloModels';
import LoadingComponent from '../shared/LoadingDropDownSelection/LoadingComponent';
import SearchInput from '../shared/SearchInput/SearchInput';
import TableLoadingAnimation from '../shared/TableLoading/TableLoadingAnimation';
import ControlCatalogAddRecord from './ControlCatalogAddRecord';
import ControlCatalogEditRecord from './ControlCatalogEditRecord';
import ScatterChart from './../Charts/ControlAnalysis/ScatteredChart';
import { useHandleTheme } from './../hooks/useHandleTheme';
import VulnerabilityHeatMap from './../Charts/ControlAnalysis/VulnerabilityHeatMap';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CONTROL_ANALYSIS, UPDATE_CONTROL_ANALYSIS_MUTATION } from '../graphql/client/query';
import { getBackgroundColor } from '../utils/getBackgroundColor';
import { sensetivities } from '../data/data';
const ControlAnalysisClientAdmin = () => {
  const [sensFilter, setRadioFilter] = useState('low');
  const [searchValue, setSearchValue] = useState('');
  const [showHide, setShowHide] = useState(false);
  const [addOrEditModal, setAddOrEditModal] = useState('');
  const [openTab, setOpenTab] = useState(1);

  const [showEdit, setShowEdit] = useState(false);
  const toggleEdit = () => setShowEdit(!showEdit);

  //CONFIGURATIONS DATA
  const [controlDomainConfigData, setControlDomainConfigData] = useState([]);
  const [controlTypeConfigData, setControlTypeConfigData] = useState([]);
  const [controlApplicabilityConfigData, setControlApplicabilityConfigData] =
    useState([]);
  const [threatVectors, setThreatVectors] = useState([]);
  const [industryUseCaseConfigData, setIndustryUseCaseConfigData] = useState(
    []
  );
  console.log(controlTypeConfigData, 'controlApplicabilityConfigData');


  //adding model handler
  const [showHideAdd, setShowHideAdd] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const handleModalShowHideAdd = () => setShowHideAdd(!showHideAdd);
  // const { data, isLoading, fetchData, isError } = useApi(
  //   `${baseURL}/${CLIENT_CONTROLS_CATALOG_ENDPOINT}`
  // );
  const [page, setPage] = useState(0);
  const pageSize = 10; // Number of items per page

  const { data, loading, error, refetch: fetchData } = useQuery(GET_CONTROL_ANALYSIS, {
    variables: { limit: pageSize, offset: page * pageSize },
  });
  console.log(data, 'data');
  const { darkMode } = useHandleTheme();

  // const { data, isLoading, isError } = useApi(
  // 	`${baseURL}/threat-catalog/control-analysis?businessProcessId=${processId}`
  //   ); // Replace with your API endpoint
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState('');
  const [allBusinessProcess, setAllBusinessProcess] = useState([]);
  const [form, setForm] = useState({}); //form
  const [errors, setErrors] = useState({}); //errors
  const [dataEdit, setDataEdit] = useState([]);

  console.log(getControlCatalogIds(data), 'contol send');
  const controls = getControlCatalogIds(data);
  const [feedback, setFeedBack] = useState();

  const [domainNameList, setDomainNameList] = useState([]);
  const handleInputChange = (newValue) => {
    setSearchValue(newValue);
    setPageNumber(0); // Reset to the first page when search value changes
  };
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      //if there is an error add the error to errors object
      setErrors({
        ...errors,
        [field]: null,
      });
  };
  const validateForm = () => {
    const {
      ControlName = '',
      ControlApplicability = '',
      controlDescription = '',
      controlType = '',
      primaryThreat = '',
      useCase = '',
      businessProcess = '',
    } = form; //declare the variables and assign the values from the form object
    const newErrors = {};
    if (!ControlName || ControlName === '')
      newErrors.ControlName = 'Control Name is required.';
    if (!ControlApplicability || ControlApplicability === '')
      newErrors.ControlApplicability = 'Control Applicability is required.';
    if (!controlDescription || controlDescription === '')
      newErrors.controlDescription = 'Control Description is required.';
    if (!controlType || controlType === '')
      newErrors.controlType = 'Control Type is required.';
    if (!primaryThreat || primaryThreat === '')
      newErrors.primaryThreat = 'Primary Threat is required.';
    if (!useCase || useCase === '')
      newErrors.useCase = 'Control Use Case is required.';
    if (!businessProcess || businessProcess === '')
      newErrors.businessProcess = 'Business Process is required.';
    return newErrors;
  };
  const fetchDatas = async () => {
    try {
      const allBusinessProcessRes = await http.get(
        `${baseURL}/business_process/get-my-business-process`
      );
      setAllBusinessProcess(allBusinessProcessRes.data);

      const controlDomainResponse = await http.get(
        `${baseURL}/configurations/control-domain`
      );
      setControlDomainConfigData(controlDomainResponse.data);

      const controlTypeResponse = await http.get(
        `${baseURL}/excel-upload/control-type`
      );
      setControlTypeConfigData(controlTypeResponse.data);

      const controlApplicabilityResponse = await http.get(
        `${baseURL}/excel-upload/control-applicability`
      );
      setControlApplicabilityConfigData(controlApplicabilityResponse.data);

      const controlUseCaseResponse = await http.get(
        `${baseURL}/configurations/industry-usecase`
      );
      setIndustryUseCaseConfigData(controlUseCaseResponse.data);

      const threatVectorResponse = await http.get(
        `${baseURL}/threat-catalog/threat-vectors`
      );
      setThreatVectors(threatVectorResponse.data);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   fetchDatas();
  // }, []);

  const uniqueThreats = removeDuplicates(threatVectors);
  console.log(uniqueThreats, 'uniqueThreats');
  // Handle dropdown selection
  const handleDropdownSelect = (eventKey) => {
    setSelectedItem(eventKey);
  };

  // Update the filtering and display logic
  const filteredData = data?.control_analysis?.filter(
    (row) =>
      row?.control_type?.match(new RegExp(searchValue, 'i')) ||
      row?.control_applicability?.match(new RegExp(searchValue, 'i')) ||
      row?.usecase?.match(new RegExp(searchValue, 'i')) ||
      row?.control_name?.match(new RegExp(searchValue, 'i')) ||
      row?.primary_threat?.match(new RegExp(searchValue, 'i'))
  );
  const [description, setDescription] = useState('');
  const handleModalShowHides = () => {
    setShowHide(!showHide);
  };
  let localStorageData = JSON.parse(localStorage.getItem('cyber-minds'));
  const processId = localStorageData?.pid;
  const clientId = localStorageData?.user?.client;

  console.log(data, 'data');
  const [pageNumber, setPageNumber] = useState(0);

  const usersPerPage = 5;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(filteredData?.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const [checkboxState, setCheckboxState] = useState({}); // Use an object instead of an array
  const [checkboxStateImplemented, setImplementedCheckboxState] = useState({});

  useEffect(() => {
    if (data?.control_analysis) {
      const applicabilityState = {};
      const implementationState = {};

      // Update the state based on control id
      data?.control_analysis?.forEach((control) => {
        applicabilityState[control.id] = control.is_applicable;
        implementationState[control.id] = control.is_implemented;
      });

      setCheckboxState(applicabilityState);
      setImplementedCheckboxState(implementationState);
    }
  }, [data?.control_analysis]);

  useEffect(() => {
    if (data?.control_analysis?.threat_risk_profile) {
      setRadioFilter(data?.control_analysis.threat_risk_profile.sensFilter);
    }
  }, [data?.control_analysis]);
  console.log(checkboxState, 'checkboxes');

  const refreshCalculate = async () => {
    // Display a success message
    setUpdateLoading(true);

    try {
      // Fetch the data from the API and wait for the response
      // fetchData();

      // Display a success message or update the UI as needed
    } catch (error) {
      console.error('Error fetching data or setting checkbox state: ', error);
      // Handle the error, e.g., show an error message to the user
    } finally {
      // After everything is done, clear the loading indicator
      setUpdateLoading(false);
    }
  };
  const [update] = useMutation(UPDATE_CONTROL_ANALYSIS_MUTATION);

  // Function to send a PATCH request

  // Function to send a PATCH request
  const updateControlProperty = async (controlId, property, updatedValue) => {
    console.log(controlId, property, updatedValue, "check");

    // Define mutation variables
    const variables = { id: parseInt(controlId) };

    // Handle which field to update and set admin fields accordingly
    if (property === 'is_applicable') {
      variables.isApplicable = updatedValue;  // Update the main property
      variables.isApplicableByAdmin = updatedValue;  // Mimic for admin field
    } else if (property === 'is_implemented') {
      variables.isImplemented = updatedValue;  // Update the main property
      variables.isImplementedByAdmin = updatedValue;  // Mimic for admin field
    } else {
      throw new Error('Unsupported property'); // Handle unsupported properties
    }

    try {
      const response = await update({
        variables: variables,
      });
      // fetchData();
      console.log('Affected rows:', response.data.update_control_analysis.affected_rows);
    } catch (error) {
      console.error('Error updating control analysis:', error);
      throw error; // Handle the error as needed
    }
  };

  // Event handler for checkbox changes
  const handleCheckboxChange = async (controlId, property) => {
    if (data?.control_analysis) {
      // Find the index of the control with the given id
      const index = data?.control_analysis?.findIndex(
        (control) => control?.id === controlId
      );

      if (index !== -1) {
        // Clone the state object to avoid direct mutation
        const updatedState = { ...checkboxState };

        // Update the checkbox state for the specific control
        updatedState[controlId] = !updatedState[controlId];

        // Update the state with the new checkbox state
        setCheckboxState(updatedState);

        const updatedValue = updatedState[controlId];

        try {
          await updateControlProperty(controlId, `${property}`, updatedValue);
          // If successful, you can display a success message or update the UI as needed
        } catch (error) {
          console.error('Error updating control property: ', error);
          // Handle the error, e.g., show an error message to the user
        }
      }
    }
  };

  // Event handler for checkbox changes
  const handleCheckboxChangeImplemnted = async (
    controlId,
    property,
    isImplemented
  ) => {
    // Check if checkboxState is defined
    if (data?.control_analysis) {
      // Find the index of the control with the given id
      const index = data?.control_analysis?.findIndex(
        (control) => control?.id === controlId
      );

      if (index !== -1) {
        // Clone the state object to avoid direct mutation
        const updatedState = { ...checkboxStateImplemented };

        // Update the checkbox state for the specific control
        updatedState[controlId] = isImplemented
          ? !updatedState[controlId]
          : isImplemented;

        // Update the state with the new checkbox state
        setImplementedCheckboxState(updatedState);

        const updatedValue = updatedState[controlId];

        try {
          await updateControlProperty(controlId, `${property}`, updatedValue);
          // If successful, you can display a success message or update the UI as needed
        } catch (error) {
          console.error("Error updating control property: ", error);
          // Handle the error, e.g., show an error message to the user
        }
      }
    }
  };


  const DisplayData = data?.control_analysis
    ?.map((controls, index) => {
      return (
        <tr key={controls.id}>
          <td>
            {controls?.control?.primary_threat?.name ?? "N/A"}
          </td>{" "}
          <td>
            {controls?.control?.secondary_threats?.map((item) => item?.name).join(', ') || "N/A"}
          </td>{" "}
          <td>
            {controls?.control?.control_domain?.label ?? "N/A"}
          </td>{" "}
          <td>
            {controls?.control?.control_category?.label ?? "N/A"}
          </td>{" "}
          <td>
            {controls?.control?.name ?? "N/A"}
          </td>
          <td>
            {controls?.control?.control_type?.label ?? "N/A"}
          </td>
          <td>
            {controls?.control?.relevance ?? "N/A"}
          </td>
          <td>
            {controls?.control?.effectiveness ?? "N/A"}
          </td>

          <td>
            <input
              id={controls.id}
              type="checkbox"
              // checked={controls.is_implemented}
              checked={checkboxState[controls.id] || false}
              onChange={() => {
                const newApplicableState = !checkboxState[controls.id];
                if (newApplicableState === false) {
                  // Set "is_implemented" to false if "is_applicable" is unchecked
                  handleCheckboxChangeImplemnted(
                    controls.id,
                    "is_implemented",
                    false
                  );

                }
                handleCheckboxChange(controls.id, "is_applicable");
              }}
            />
          </td>
          <td>
            <input
              id={controls.id}
              type="checkbox"
              checked={checkboxStateImplemented[controls.id]}
              // checked={controls.is_applicable}
              disabled={!checkboxState[controls.id]}
              onChange={() => {
                handleCheckboxChangeImplemnted(
                  controls.id,
                  "is_implemented",
                  true
                );
              }}
            />
          </td>
          <td className="text-white font-semibold">
            <p
              className="p-1.5 text-center rounded-xl"
              style={{
                backgroundColor: getBackgroundColor(
                  controls?.risk_profile?.[sensFilter]
                ),
                color: "black",
              }}
            >
              {controls?.threat_risk_profile?.value?.toFixed(2)}
            </p>
          </td>

        </tr>
      );
    });

  return (
    <div>
      <div className="p-10 ">
        {updateLoading ? <LoadingComponent /> : null}
        <div className={openTab === 1 ? 'block' : 'hidden'}>
          <div className="table-title">
            <span className="">Control Analysis</span>
            <div className="flex justify-center items-center space-x-6">
              <Button
                className="btn-add-new"
                onClick={() => {
                  refreshCalculate();
                }}
              >
                Recalculate
              </Button>
              <SearchInput
                searchValue={searchValue}
                setSearchValue={handleInputChange}
              />
            </div>
            <div className=" flex justify-center items-center space-x-4 bg-white rounded-md">
              {sensetivities?.map((items) => {
                return (
                  <div className="flex justify-end items-end p-2.5 ">
                    <div class=" flex justify-center items-center">
                      <input
                        class="form-check-input appearance-none rounded-full h-6 w-6 border border-gray-300  checked:bg-blue-600 checked:border-green-600 focus:outline-none transition duration-200  align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        type="radio"
                        name="flexRadioDefault"
                        id={items.name}
                        checked={items.name === sensFilter}
                        onClick={() => setRadioFilter(`${items.name}`)}
                      />
                      <label
                        class="form-check-label inline-block text-gray-800"
                        for="flexRadioDefault1"
                      >
                        {items.name}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div className="overflow-auto">
              <div className="overflow-x-auto">
                <table className="tables ">
                  <thead className="sticky-header ">
                    <tr>
                      <th scope="col">Primary Threat</th>{" "}
                      <th scope="col">Secondary Threat</th>{" "}
                      <th scope="col">Control Domain</th>{" "}
                      <th scope="col">Control Category</th>
                      <th scope="col">Control Name</th>
                      <th scope="col">Control Type</th>{" "}
                      <th scope="col">
                        Control Relevance
                        <button
                          type="button"

                        >
                        </button>
                      </th>
                      <th scope="col">
                        Control Effectiveness %{" "}
                        <button
                          type="button"

                        >
                        </button>
                      </th>
                      <th scope="col">Applicability</th>
                      <th scope="col">Implemented?</th>
                      {/* 
											<th scope="col">Control Applicability</th>
											<th scope="col">Control Use Case</th> */}
                      <th scope="col">
                        Threat Risk Profile
                        <button
                          type="button"
                        >
                        </button>
                      </th>

                    </tr>
                  </thead>
                  <tbody>{DisplayData}</tbody>
                </table>
                {loading ? <TableLoadingAnimation /> : null}
              </div>
            </div>
            <div>
              {filteredData?.length > 5 ? (
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  activeClassName={'paginationActive'}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={'paginationBttns'}
                  previousLinkClassName={'previousBttn'}
                  nextLinkClassName={'nextBttn'}
                  disabledClassName={'paginationDisabled'}
                />
              ) : null}
            </div>
          </div>
        </div>
        {/* adding model */}
        <Modal show={showHideAdd} size="lg">
          <Modal.Header onClick={() => handleModalShowHideAdd()}>
            <Modal.Title>Add New Control Analysis Record</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ControlCatalogAddRecord
              url="/threat-catalog/client-controls-catalog"
              handleModalShowHideAdd={handleModalShowHideAdd}
              fetchCatalogs={fetchData}
            />
          </Modal.Body>
        </Modal>

        <Modal show={showEdit} size="lg">
          <Modal.Header onClick={() => toggleEdit()}>
            <Modal.Title>Edit New Control Analysis Record</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ControlCatalogEditRecord
              url="/threat-catalog/client-controls-catalog"
              handleModalShowHideAdd={toggleEdit}
              fetchCatalogs={fetchData}
              dataEdit={dataEdit}
            />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default ControlAnalysisClientAdmin;
