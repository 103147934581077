import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import http from '../../resources/http';
import { baseURL } from '../../resources/apiClient';
import swal from 'sweetalert';
import { Button, Form, Modal } from 'react-bootstrap';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  textField: {
    width: 100,
    height: 40,
  },
  menu: {
    width: 200,
  },
});

const AddThreatVectorsSuperAdmin = ({ refresh, handleModalShowHide }) => {
  const [details, setDetails] = useState(false);
  const toggleDetails = () => setDetails(!details);
  const [showControlForm, setshowControlForm] = useState(false);
  const toggleControlForm = (e) => {
    e.preventDefault();
    setshowControlForm(!showControlForm);
  };
  const [name, setName] = useState();
  const [costOfBreach, setCostOfBreach] = useState();
  const [costOfIncident, setCostOfIncident] = useState();
  const [threatVectorIncident, setThreatVectorIncident] = useState();
  const [percentOfBreach, setPercentOfBreach] = useState();
  const [lostOfBusinessCost, setLostOfBusinessCost] = useState();
  const [threatScenario, setThreatScenario] = useState();
  const [threatDescription, setThreatDescription] = useState('');
  const [threatCategory, setThreatCategory] = useState('');
  const [category, setCategory] = useState([]);
  const [discoverability, setDiscoverability] = useState('');
  const [discover, setDiscover] = useState([]);
  const [exploitability, setExploitability] = useState('');
  const [exploit, setExploit] = useState([]);
  const [reproducibility, setReproducibility] = useState('');
  const [reproduce, setReproduce] = useState([]);
  const [availability, setAvailability] = useState('');
  const [available, setAvailable] = useState([]);
  const [integrity, setIntegrity] = useState('');
  const [integrities, setIntegrities] = useState([]);
  const [confidentiality, setConfidentiality] = useState('');
  const [confident, setConfident] = useState([]);
  const [description, setDescription] = useState('');
  const [showDetail, setShowDetail] = useState(false);

  const [threatVectors, setThreatVectors] = useState({}); //threat vectors
  const [errors, setErrors] = useState({}); //errors

  const [charCount, setCharCount] = React.useState(0);
  const [scenarioCharCount, setScenarioCharCount] = React.useState(0);
  let charLimit = 3000;

  const [errorMessage, setErrorMessage] = useState(
    'Invalid Input! Percentage value should be between 0 and 100'
  );

  const setField = (field, value) => {
    setThreatVectors({
      ...threatVectors,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const validateThreatRepository = () => {
    const {
      name = '',
      threatCategory = '',
      discoverability = '',
      exploitability = '',
      reproducibility = '',
      confidentiality = '',
      availability = '',
      integrity = '',
    } = threatVectors;
    const newErrors = {};

    if (!name || name === '' || name === 'Select...')
      newErrors.name = 'Threat Vector is require.';

    if (
      !threatCategory ||
      threatCategory === '' ||
      threatCategory === 'Select...'
    )
      newErrors.threatCategory = 'Threat Category is require.';

    if (
      !discoverability ||
      discoverability === '' ||
      discoverability === 'Select...'
    )
      newErrors.discoverability = 'Discoverability is require';

    if (
      !exploitability ||
      exploitability === '' ||
      exploitability === 'Select...'
    )
      newErrors.exploitability = 'Exploitability is require.';

    if (
      !reproducibility ||
      reproducibility === '' ||
      reproducibility === 'Select...'
    )
      newErrors.reproducibility = 'Reproducibility is require.';

    if (
      !confidentiality ||
      confidentiality === '' ||
      confidentiality === 'Select...'
    )
      newErrors.confidentiality = 'Confidentiality is require.';

    if (!integrity || integrity === '' || integrity === 'Select...')
      newErrors.integrity = 'Integrity is require.';

    if (!availability || availability === '' || availability === 'Select...')
      newErrors.availability = 'Availability is require.';

    return newErrors;
  };

  const toggleDetail = () => {
    setShowDetail(!showDetail);
  };
  const clearFields = () => {
    setThreatCategory('');
    setDiscoverability('');
    setExploitability('');
    setReproducibility('');
    setAvailability('');
    setIntegrity('');
    setConfidentiality('');
    setName('');
    setCostOfBreach('');
    setCostOfIncident('');
    setThreatVectorIncident('');
    setPercentOfBreach('');
    setLostOfBusinessCost('');
    setThreatDescription('');
    setThreatScenario('');
  };
  const submit = (e) => {
    e.preventDefault();
    const assetErrors = validateThreatRepository();
    if (Object.keys(assetErrors).length > 0) {
      setErrors(assetErrors);
    } else {
      http
        .post(`${baseURL}/threat-catalog/threat-vectors`, {
          name: threatVectors.name,
          category: threatVectors.threatCategory,
          discoverability: threatVectors.discoverability,
          exploitablity: threatVectors.exploitability,
          reproducibility: threatVectors.reproducibility,
          confidentiality: threatVectors.confidentiality,
          integrity: threatVectors.integrity,
          availability: threatVectors.availability,
          event_frequency: threatVectors.threatVectorIncident
            ? threatVectors.threatVectorIncident
            : 0,
          cost_of_incident: threatVectors.costOfIncident
            ? threatVectors.costOfIncident
            : 0,
          cost_of_breach: threatVectors.costOfBreach
            ? threatVectors.costOfBreach
            : 0,
          percent_of_breach: threatVectors.percentOfBreach
            ? threatVectors.percentOfBreach
            : 0,
          lost_business_cost: threatVectors.lostOfBusinessCost
            ? threatVectors.lostOfBusinessCost
            : 0,
          description: threatVectors.threatDescription,
          threat_senario: threatVectors.threatScenario,
        })
        .then(
          (response) => {
            swal('Success', 'Threat Vector Added Successfully', 'success');
            clearFields();
          },
          (err) => {
            console.log(err?.response);
          }
        );
    }
  };
  const fetchData = () => {
    http
      .get(`${baseURL}/threat-catalog/threat-categories/`)
      .then((response) => {
        setCategory(response.data);
      })
      .then(
        (response) => { },
        (err) => {
          console.log(err);
        }
      );
    http
      .get(`${baseURL}/threat-catalog/discoverability-levels/`)
      .then((response) => {
        setDiscover(response.data);
      })
      .then(
        (response) => { },
        (err) => {
          console.log(err);
        }
      );
    http
      .get(`${baseURL}/threat-catalog/exploitablity-levels/`)
      .then((response) => {
        setExploit(response.data);
      })
      .then(
        (response) => { },
        (err) => {
          console.log(err);
        }
      );
    http
      .get(`${baseURL}/threat-catalog/reproducibilities/`)
      .then((response) => {
        setReproduce(response.data);
      })
      .then(
        (response) => { },
        (err) => {
          console.log(err);
        }
      );
    http
      .get(`${baseURL}/threat-catalog/availability-levels/`)
      .then((response) => {
        setAvailable(response.data);
      })
      .then(
        (response) => { },
        (err) => {
          console.log(err);
        }
      );
    http
      .get(`${baseURL}/threat-catalog/integrity-levels/`)
      .then((response) => {
        setIntegrities(response.data);
      })
      .then(
        (response) => { },
        (err) => {
          console.log(err);
        }
      );
    http
      .get(`${baseURL}/threat-catalog/confidentiality-levels/`)
      .then((response) => {
        setConfident(response.data);
      })
      .then(
        (response) => { },
        (err) => {
          console.log(err);
        }
      );
  };
  // useEffect(() => {
  //             // fetchData();
  // }, []);
  const classes = styles();
  return (
    <div className="grid place-items-center">
      <Modal.Body className="grid grid-cols-2  gap-4">
        <Form.Group class="mb-6">
          <Form.Label
            for="name"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Threat Vector Name
          </Form.Label>
          <Form.Control
            type="text"
            id="name"
            value={threatVectors.name}
            onChange={(e) => setField('name', e.target.value)}
            class="bg-gray-50 border border-gray-300 text-gray-900  rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Text..."
            isInvalid={!!errors.name}
            name="name"
            autoFocus
            required
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group class="mb-6">
          <Form.Label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Threat Category
          </Form.Label>
          <Form.Control
            as="select"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={threatVectors.threatCategory}
            required
            onChange={(e) => setField('threatCategory', e.target.value)}
            isInvalid={!!errors.threatCategory}
          >
            <option>Select...</option>
            {category?.map((x, y) => (
              <option value={x.id} key={y}>
                {x.name}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.threatCategory}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group class="mb-6">
          <Form.Label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Discoverability
          </Form.Label>
          <Form.Control
            as="select"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={threatVectors.discoverability}
            required
            onChange={(e) => setField('discoverability', e.target.value)}
            isInvalid={!!errors.discoverability}
          >
            <option>Select...</option>
            {discover?.map((x, y) => (
              <option value={x.id} key={y}>
                {x.difficulty_level}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.discoverability}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group class="mb-6">
          <Form.Label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Exploitablity
          </Form.Label>
          <Form.Control
            as="select"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={threatVectors.exploitability}
            required
            onChange={(e) => setField('exploitability', e.target.value)}
            isInvalid={!!errors.exploitability}
          >
            <option>Select...</option>
            {exploit?.map((x, y) => (
              <option value={x.id} key={y}>
                {x.difficulty_level}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.exploitability}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group class="mb-6">
          <Form.Label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Reproducibility
          </Form.Label>
          <Form.Control
            as="select"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={threatVectors.reproducibility}
            required
            onChange={(e) => setField('reproducibility', e.target.value)}
            isInvalid={!!errors.reproducibility}
          >
            <option>Select...</option>
            {reproduce?.map((x, y) => (
              <option value={x.id} key={y}>
                {x.difficulty_level}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.reproducibility}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group class="mb-6">
          <Form.Label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Confidentiality
          </Form.Label>
          <Form.Control
            as="select"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={threatVectors.confidentiality}
            required
            onChange={(e) => setField('confidentiality', e.target.value)}
            isInvalid={!!errors.confidentiality}
          >
            <option>Select...</option>
            {confident?.map((x, y) => (
              <option value={x.id} key={y}>
                {x.difficulty_level}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.confidentiality}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group class="mb-6">
          <Form.Label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Integrity
          </Form.Label>
          <Form.Control
            as="select"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={threatVectors.integrity}
            required
            onChange={(e) => setField('integrity', e.target.value)}
            isInvalid={!!errors.integrity}
          >
            <option>Select...</option>
            {integrities?.map((x, y) => (
              <option value={x.id} key={y}>
                {x.difficulty_level}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.integrity}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group class="mb-6">
          <Form.Label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Availability
          </Form.Label>
          <Form.Control
            as="select"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            value={threatVectors.availability}
            required
            onChange={(e) => setField('availability', e.target.value)}
            isInvalid={!!errors.availability}
          >
            <option>Select...</option>
            {available?.map((x, y) => (
              <option value={x.id} key={y}>
                {x.difficulty_level}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.availability}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group class="mb-6">
          <Form.Label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Threat Description
          </Form.Label>
          <Form.Control
            as="textarea"
            type="text"
            id="message"
            maxLength={charLimit}
            value={threatDescription}
            onChange={(e) => {
              setThreatDescription(e.target.value);
              setCharCount(e.target.value.length);
            }}
            rows="4"
            class="block p-2.5 w-full  text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Text..."
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {charCount > charLimit / 2 ? (
              <div className="flex justify-end">
                <p className="right-0 text-red-500 font-medium">{`${charCount}/${charLimit}`}</p>
              </div>
            ) : (
              ''
            )}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group class="mb-6">
          <Form.Label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Threat Scenario
          </Form.Label>
          <Form.Control
            as="textarea"
            type="text"
            id="message"
            maxLength={charLimit}
            value={threatScenario}
            onChange={(e) => {
              setThreatScenario(e.target.value);
              setScenarioCharCount(e.target.value.length);
            }}
            rows="4"
            class="block p-2.5 w-full  text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 "
            placeholder="Text..."
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {scenarioCharCount > charLimit / 2 ? (
              <div className="flex justify-end">
                <p className="right-0 text-red-500 font-medium">{`${scenarioCharCount}/${charLimit}`}</p>
              </div>
            ) : (
              ''
            )}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group class="mb-6">
          <Form.Label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Cost of Breach in USD (Optional)
          </Form.Label>
          <Form.Control
            value={costOfBreach}
            onChange={(e) => setCostOfBreach(e.target.value)}
            type="number"
            min={0}
            name="text"
            id="text"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            required
          ></Form.Control>
        </Form.Group>

        <Form.Group class="mb-6">
          <Form.Label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Percent of Breach % (Optional)
          </Form.Label>
          <Form.Control
            type="number"
            min={0}
            name="text"
            value={percentOfBreach}
            onChange={(e) => setPercentOfBreach(e.target.value)}
            id="text"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            required
          ></Form.Control>
        </Form.Group>

        <Form.Group class="mb-6">
          <Form.Label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Threat Event Frequency % (Optional)
          </Form.Label>
          <Form.Control
            value={threatVectorIncident}
            onChange={(e) => setThreatVectorIncident(e.target.value)}
            type="number"
            min={0}
            name="text"
            id="text"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            required
          ></Form.Control>
        </Form.Group>

        <Form.Group class="mb-6">
          <Form.Label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Cost Per Incident in USD (Optional)
          </Form.Label>
          <Form.Control
            value={costOfIncident}
            onChange={(e) => setCostOfIncident(e.target.value)}
            type="number"
            min={0}
            name="text"
            id="text"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            required
          ></Form.Control>
        </Form.Group>

        <Form.Group class="mb-6">
          <Form.Label
            for="email"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Lost Business Cost in USD (Optional)
          </Form.Label>
          <Form.Control
            min={0}
            type="number"
            name="password"
            size={2}
            id="password"
            value={lostOfBusinessCost}
            onChange={(e) => setLostOfBusinessCost(e.target.value)}
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            required
          ></Form.Control>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            handleModalShowHide();
            clearFields();
          }}
          className="btn-cancel"
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            submit(e);
            clearFields();
          }}
          className="btn-add-new"
          className="w-24"
        >
          Add
        </Button>
      </Modal.Footer>

      {/* Description pop up */}
      {showDetail && (
        <div
          id="defaultModal"
          tabindex="-1"
          aria-hidden="true"
          class="grid place-items-center overflow-y-auto overflow-x-hidden fixed top-0  z-50 w-full  h-modal md:h-full"
        >
          <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
            <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button
                onClick={() => toggleDetail()}
                type="button"
                class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-toggle="authentication-modal"
              >
                <svg
                  class="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
              <div class="flex justify-between items-start p-1 rounded-t border-b dark:border-gray-600">
                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                  Detail
                </h3>
              </div>
              <div class="p-6 space-y-6">
                <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                  {description}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

AddThreatVectorsSuperAdmin.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default AddThreatVectorsSuperAdmin;
