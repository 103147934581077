import React, { useState, useEffect } from "react";
import http from "../../resources/http";
import Modal from "../../shared/Modal";
import Form from "../../shared/Form";
import {
	AVAILABILITY_LEVELS,
	CONFIDENTIALITY_LEVELS,
	DISCOVERABILITY_LEVELS,
	EXPLOITABILITY_LEVELS,
	INTEGRITY_LEVELS,
	REPRODUCIBILITIES,
	THREAT_CATEGORIES,
} from "../../api/threatCatalog";
import LoadingComponent from "../../shared/LoadingDropDownSelection/LoadingComponent";
import swal from "sweetalert";

const ModalHandler = ({
	isOpen,
	mode,
	onClose,
	size,
	header,
	selectedItem,
	selectedItemId,

	url_create,
	url_by_pk,
	fetchData,
}) => {
	const [fetchLoading, setFetchLoading] = useState(false);

	const [availabilityDropdownOptions, setAvailabilityDropdownOptions] =
		useState([]);
	const [categoryDropdownOptions, setCategoryDropdownOptions] = useState([]);
	const [confidentialityDropdownOptions, setConfidentialityDropdownOptions] =
		useState([]);
	const [discoverabilityDropdownOptions, setDiscoverabilityDropdownOptions] =
		useState([]);
	const [exploitablityDropdownOptions, setExploitablityDropdownOptions] =
		useState([]);
	const [integrityDropdownOptions, setIntegrityDropdownOptions] = useState([]);
	const [reproducibilityDropdownOptions, setReproducibilityDropdownOptions] =
		useState([]);
	async function fetchFormOptionData() {
		try {
			const listOfAvailability = await http.get(AVAILABILITY_LEVELS);
			const listOfCategory = await http.get(THREAT_CATEGORIES);
			const listOfConfidentiality = await http.get(CONFIDENTIALITY_LEVELS);
			const listOfDiscoverability = await http.get(DISCOVERABILITY_LEVELS);
			const listOfExploitablity = await http.get(EXPLOITABILITY_LEVELS);
			const listOfIntegrity = await http.get(INTEGRITY_LEVELS);
			const listOfReproducibility = await http.get(REPRODUCIBILITIES);

			const listOfAvailabilityOptionsData = listOfAvailability.data?.map(
				(item) => item.difficulty_level
			);
			setAvailabilityDropdownOptions(listOfAvailabilityOptionsData);

			const listOfCategoryOptions = listOfCategory.data?.map(
				(item) => item.name
			);
			setCategoryDropdownOptions(listOfCategoryOptions);

			const listOfConfidentialityOptions = listOfConfidentiality.data?.map(
				(item) => item.difficulty_level
			);
			setConfidentialityDropdownOptions(listOfConfidentialityOptions);

			const listOfDiscoverabilityOptions = listOfDiscoverability.data?.map(
				(item) => item.difficulty_level
			);
			setDiscoverabilityDropdownOptions(listOfDiscoverabilityOptions);

			const listOfExploitablityOptions = listOfExploitablity.data?.map(
				(item) => item.difficulty_level
			);
			setExploitablityDropdownOptions(listOfExploitablityOptions);

			const listOfIntegrityOptions = listOfIntegrity.data?.map(
				(item) => item.difficulty_level
			);
			setIntegrityDropdownOptions(listOfIntegrityOptions);

			const listOfReproducibilityOptions = listOfReproducibility.data?.map(
				(item) => item.difficulty_level
			);
			setReproducibilityDropdownOptions(listOfReproducibilityOptions);
		} catch (error) {
			console.log("error occurred while fetching form dropdown options.");
		}
	}
	useEffect(() => {
		if (isOpen) {
			fetchFormOptionData();
		}
	}, [isOpen]);

	const initialState = {
		availability: "",
		category: "",
		confidentiality: "",
		cost_of_breach: "",
		cost_of_incident: "",
		description: "",
		discoverability: "",
		exploitablity: "",
		integrity: "",
		lost_business_cost: "",
		name: "",
		percent_of_breach: "",
		reproducibility: "",
		threat_senario: "",
	};
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({ initialState });
	const [errors, setErrors] = useState({});

	const validations = {
		name: (value) => (value ? "" : "This field is required."),
		availability: (value) => (value ? "" : "This field is required."),
		category: (value) => (value ? "" : "This field is required."),
		discoverability: (value) => (value ? "" : "This field is required."),
		exploitablity: (value) => (value ? "" : "This field is required."),
		integrity: (value) => (value ? "" : "This field is required."),
		confidentiality: (value) => (value ? "" : "This field is required."),
		reproducibility: (value) => (value ? "" : "This field is required."),
	};

	const handleChange = (key, value) => {
		setFormData((prevState) => ({ ...prevState, [key]: value }));
		if (validations[key]) {
			const error = validations[key](value);
			setErrors((prevState) => ({ ...prevState, [key]: error }));
		}
	};

	const resetForm = () => {
		setFormData(initialState);
		setErrors({});
	};

	useEffect(() => {
		if (mode === "edit" && selectedItem) {
			console.log(selectedItem, mode, "selectedItem");
			setLoading(true);
			setFormData(selectedItem);
			setLoading(false);
		} else {
			resetForm();
		}
	}, [mode, selectedItem]);

	const formFieldsConfig = {
		name: {
			label: "Threat Vector Name",
			type: "text",
			placeholder: "text...",
			value: formData?.name,
			onChange: (e) => handleChange("name", e.target.value),
			error: errors.name,
		},
		category: {
			label: "Threat Category",
			type: "select",
			placeholder: "Select...",
			options: categoryDropdownOptions,
			value: formData.category || "",
			onChange: (e) => handleChange("category", e.target.value),
			error: errors.category,
		},
		discoverability: {
			label: "Discoverability",
			type: "select",
			placeholder: "Select...",
			options: discoverabilityDropdownOptions,
			value: formData.discoverability || "",
			onChange: (e) => handleChange("discoverability", e.target.value),
			error: errors.discoverability,
		},

		exploitablity: {
			label: "Exploitablity",
			type: "select",
			placeholder: "Select...",
			options: exploitablityDropdownOptions,
			value: formData.exploitablity || "",
			onChange: (e) => handleChange("exploitablity", e.target.value),
			error: errors.exploitablity,
		},

		reproducibility: {
			label: "Reproducibility",
			type: "select",
			placeholder: "Select...",
			options: reproducibilityDropdownOptions,
			value: formData.reproducibility || "",
			onChange: (e) => handleChange("reproducibility", e.target.value),
			error: errors.reproducibility,
		},
		confidentiality: {
			label: "Confidentiality",
			type: "select",
			placeholder: "Select...",
			options: confidentialityDropdownOptions,
			value: formData.confidentiality || "",
			onChange: (e) => handleChange("confidentiality", e.target.value),
			error: errors.confidentiality,
		},
		integrity: {
			label: "Integrity",
			type: "select",
			placeholder: "Select...",
			options: integrityDropdownOptions,
			value: formData.integrity || "",
			onChange: (e) => handleChange("integrity", e.target.value),
			error: errors.integrity,
		},
		availability: {
			label: "Availability",
			type: "select",
			placeholder: "Select...",
			options: availabilityDropdownOptions,
			value: formData.availability || "",
			onChange: (e) => handleChange("availability", e.target.value),
			error: errors.availability,
		},
		cost_of_breach: {
			label: "Cost of Breach in USD (Optional)",
			type: "number",
			min: 0,
			placeholder: "Number...",
			value: formData?.cost_of_breach,
			onChange: (e) => handleChange("cost_of_breach", e.target.value),
			error: errors.cost_of_breach,
		},
		percent_of_breach: {
			label: "Percent of Breach % (Optional)",
			type: "number",
			min: 0,
			max: 100,
			placeholder: "Number...",
			value: formData?.percent_of_breach,
			onChange: (e) => handleChange("percent_of_breach", e.target.value),
			error: errors.percent_of_breach,
		},
		event_frequency: {
			label: "Threat Event Frequency % (Optional)",
			type: "number",
			min: 0,
			placeholder: "Number...",
			value: formData?.event_frequency,
			onChange: (e) => handleChange("event_frequency", e.target.value),
			error: errors.event_frequency,
		},
		cost_of_incident: {
			label: "Cost Per Incident in USD (Optional)",
			type: "number",
			min: 0,
			placeholder: "Number...",
			value: formData?.cost_of_incident,
			onChange: (e) => handleChange("cost_of_incident", e.target.value),
			error: errors.cost_of_incident,
		},
		lost_business_cost: {
			label: "Lost Business Cost in USD (Optional)",
			type: "number",
			min: 0,
			placeholder: "Number...",
			value: formData?.lost_business_cost,
			onChange: (e) => handleChange("lost_business_cost", e.target.value),
			error: errors.lost_business_cost,
		},
		description: {
			label: "Threat Description",
			type: "textarea",
			placeholder: "text...",
			value: formData?.description,
			onChange: (e) => handleChange("description", e.target.value),
			error: errors.description,
		},
		threat_senario: {
			label: "Threat Scenario",
			type: "textarea",
			placeholder: "text...",
			value: formData?.threat_senario,
			onChange: (e) => handleChange("threat_senario", e.target.value),
			error: errors.threat_senario,
		},
	};

	const handleSubmit = () => {
		let newErrors = {};
		Object.entries(formFieldsConfig).forEach(([key, config]) => {
			if (validations[key]) newErrors[key] = validations[key](formData[key]);
		});
		setErrors(newErrors);
		if (Object.values(newErrors).every((error) => !error)) {
			console.log("Submitting form data:", formData);
			if (mode === "edit") {
				http
					.patch(`${url_by_pk}${selectedItemId}`, formData)
					.then((res) => {
						setFetchLoading(false);
						onClose();
						swal("Success!", "It has been updated successfully", "success");
						// fetchData();
						resetForm();
					})
					.catch((error) => {
						setFetchLoading(false);
						if (error && error.response && error.response.data) {
							for (const [key, errorMessage] of Object.entries(
								error.response.data
							)) {
								if (formFieldsConfig[key]) {
									newErrors[key] = errorMessage[0];
								}
								setErrors(newErrors);
							}
						} else {
							swal("Error", "Something went wrong, please try again", "error");
						}
					});
			} else {
				http
					.post(`${url_create}`, formData)
					.then((res) => {
						setFetchLoading(false);
						onClose();
						swal("Success!", "It has been updated successfully", "success");
						// fetchData();
						resetForm();
					})
					.catch((error) => {
						setFetchLoading(false);
						if (error && error.response && error.response.data) {
							for (const [key, errorMessage] of Object.entries(
								error.response.data
							)) {
								if (formFieldsConfig[key]) {
									newErrors[key] = errorMessage[0];
								}
								setErrors(newErrors);
							}
						} else {
							swal("Error", "Something went wrong, please try again", "error");
						}
					});
			}
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size={size}>
			<Modal.Header>
				<Modal.Title>
					{mode === "edit" ? `Update  ${header}` : `Add New  ${header}`}
				</Modal.Title>
			</Modal.Header>
			{fetchLoading ? (
				<LoadingComponent />
			) : (
				<Modal.Body>
					<div className="modal-three-columns">
						<Form>
							{Object.entries(formFieldsConfig || {}).map(
								([key, fieldConfig]) => (
									<Form.Group key={key}>
										<Form.Label>{fieldConfig.label}</Form.Label>
										<Form.Control {...fieldConfig} />
										{errors[key] && (
											<Form.Control.Feedback type="invalid">
												{errors[key]}
											</Form.Control.Feedback>
										)}
									</Form.Group>
								)
							)}
						</Form>
					</div>
				</Modal.Body>
			)}

			<Modal.Footer>
				<button
					className="btn-cancel"
					onClick={() => {
						onClose();
						resetForm();
					}}
				>
					Cancel
				</button>
				<button className="btn-add-new" onClick={handleSubmit}>
					{mode === "edit" ? "Save Changes" : "Add"}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalHandler;
