import React, { useState, useEffect } from "react";
import { useMutation, gql, useQuery } from "@apollo/client";
import Modal from "../../shared/Modal";
import Form from "../../shared/Form";
import LoadingComponent from "../../shared/LoadingDropDownSelection/LoadingComponent";
import {
	GET_VENDORS,
	GET_PRODUCTS,
	GET_VERSIONS,
} from "../../graphql/client/query";
import { useLazyQuery } from "@apollo/client";
import {
	CREATE_CLIENT_ASSET,
	UPDATE_CLIENT_BIA,
} from "../../graphql/client/query";
import { GET_BIA_SUPER_ADMIN } from "../../graphql/superAdmin/query/query";
import { options } from "../../data/data";
import toast from "react-hot-toast";
import { GET_ASSET_LIST } from "../../graphql/superAdmin/query/controlConfiguration/query";

const ModalHandler = ({
	isOpen,
	selectedItemId,
	onClose,
	mode,
	size,
	header,
	selectedItem,
	fetchData,
	selectedClientId,
	selectedBusinessProcessId,
}) => {
	const [fetchLoading, setFetchLoading] = useState(false);
	const [productOptions, setProductOptions] = useState();
	const [versionOptions, setVersionOptions] = useState([]);
	const [productData, setProductData] = useState([]);
	const [cpeUri, setCpeUri] = useState('');

	// Apollo queries
	const [fetchVendors] = useLazyQuery(GET_VENDORS, {
		fetchPolicy: "network-only",
	});
	const [fetchProducts] = useLazyQuery(GET_PRODUCTS, {
		fetchPolicy: "network-only",
	});
	const [fetchVersions] = useLazyQuery(GET_VERSIONS, {
		fetchPolicy: "network-only",
	});
	const {
		data: asset,
		loading,
		error,
		refetch,
	} = useQuery(GET_ASSET_LIST, {
		variables: { limit: 100 },
	});
	const assetsList = asset?.assets?.map(items => items.name);

	// Fetch vendor suggestions dynamically
	const fetchVendorSuggestions = async (query) => {
		if (!query) return [];
		try {
			const { data } = await fetchVendors({
				variables: { starts_with: query },
			});
			return data?.all_vendor_list || [];
		} catch (error) {
			console.error("Error fetching vendors:", error);
			return [];
		}
	};

	// Fetch products for the selected vendor
	const handleVendorChange = async (vendor) => {
		setFormData((prev) => ({ ...prev, vendor, product: "", version: "" }));
		setProductOptions([]);
		setVersionOptions([]);

		if (vendor) {
			try {
				const { data } = await fetchProducts({ variables: { vendor } });
				setProductOptions(
					data?.products?.map((product) => product.component_name) || []
				);
				setProductData(data?.products || []);
			} catch (error) {
				console.error("Error fetching products:", error);
				setProductOptions([]);
			}
		}
	};

	console.log(productData, 'productWithCpeUri')
	// Fetch versions for the selected product
	const handleProductChange = async (product) => {
		setFormData((prev) => ({ ...prev, product, version: "" }));
		setVersionOptions([]);
		console.log(product, 'product')
		console.log(productOptions, 'productOptions')
		if (product) {
			try {
				const { data } = await fetchVersions({
					variables: { vendor: formData.vendor, product },
				});
				setVersionOptions(
					data?.versions?.map((version) => version.version) || []
				);
				// Find the selected product's cpeUri and update the state
				const selectedProduct = productData.find(
					(option) => option.component_name === product
				);
				setCpeUri(selectedProduct?.cpe_uri);
			} catch (error) {
				console.error("Error fetching versions:", error);
				setVersionOptions([]);
			}
		}
	};

	// Handle form input changes
	const handleChange = (key, value) => {
		setFormData((prev) => ({ ...prev, [key]: value }));
	};
	const initialState = {
		vendor: "",
		product: "",
		version: "",
		assetName: "",
		hierarchy: "",
		businessAssets: "",
		regulation: "",
		assetType: "",
		assetRisk: "",
		assetCategorization: "",
		business_process: selectedBusinessProcessId,
		client: selectedClientId,
	};

	const [formData, setFormData] = useState(initialState);
	const [errors, setErrors] = useState({});
	console.log(cpeUri, "cpe")
	const resetForm = () => {
		setFormData(initialState);
		setErrors({});
	};
	const validations = {
		product: (value) => (value ? "" : "This field is required."),
		vendor: (value) => (value ? "" : "This field is required."),
	};

	const [createClientAsset] = useMutation(CREATE_CLIENT_ASSET);
	const [updateClientAsset] = useMutation(UPDATE_CLIENT_BIA);
	function formatCPE() {
		// Ensure the base CPE has the expected structure
		if (!cpeUri.startsWith("cpe:/")) {
			throw new Error("Invalid base CPE format. It should start with 'cpe:/'.");
		}

		// Extract the vendor and product from the base CPE
		const parts = cpeUri.split("/");
		if (parts.length < 2 || !parts[1].includes(":")) {
			throw new Error("Invalid base CPE structure. It should follow 'cpe:/vendor:product'.");
		}

		const [vendor, product] = parts[1].split(":");
		// Determine the 'part' based on assetType
		const part = formData.assetType === "Application" ? 'a' :
			formData.assetType === "Operating System" ? 'o' :
				formData.assetType === "Hardware" ? 'h' : 'a';  // Default to 'a' if no match


		// Construct the CPE 2.3 format
		const standardCPE = `cpe:2.3:${part}:${formData.vendor}:${formData.product}:${formData.version}:*:*:*:*:*:*:*`;
		return standardCPE;
	}
	const handleSubmit = async () => {
		let newErrors = {};
		Object.entries(validations).forEach(([key, validate]) => {
			newErrors[key] = validate(formData[key]);
		});
		setErrors(newErrors);

		try {
			const { data } = await createClientAsset({
				variables: {
					inputs: {
						created_at: new Date().toISOString().split("T")[0], // Or use a custom date
						name: formData.assetName,
						cpe: formatCPE(),
						version: formData.version,
						vendor: formData.vendor,
						risk: formData.assetRisk,
						hierarchy: formData.hierarchy,
						client_pk: selectedClientId,
						business_process_pk: selectedBusinessProcessId,
						asset_categorization: formData.assetCategorization,
						asset_type: formData.assetType,
						business_asset: formData.businessAssets,
						asset_geolocation: formData.businessGeolocation,
						regulation: formData.regulation
					},
				},
			});

			if (data.create_client_asset.affected_rows > 0) {
				toast.success("Asset added successfully!");
				// fetchData();
				resetForm();
				onClose();
			}
		} catch (error) {
			const graphQLError =
				error?.graphQLErrors?.[0]?.message || "An unknown error occurred.";

			// Handle specific database constraint error
			if (
				graphQLError.includes(
					"duplicate key value violates unique constraint"
				) &&
				graphQLError.includes("atc_clientname_key")
			) {
				toast.error(
					`The asset name "${formData.name}" already exists. Please choose a unique asset.`
				);
			} else {
				toast.error(graphQLError);
			}
			console.error("GraphQL Error:", error);
		}
	};

	const formFieldsConfig = {
		vendor: {
			label: "Vendor",
			type: "autocomplete",
			placeholder: "Type vendor name...",
			value: formData.vendor,
			onChange: (value) => handleVendorChange(value),
			onSelect: (value) => handleVendorChange(value),
			fetchSuggestions: fetchVendorSuggestions,
			error: errors.vendor,
		},
		product: {
			label: "Product",
			type: "select",
			placeholder: "Select a product...",
			options: productOptions,
			value: formData.product,
			onChange: (e) => handleProductChange(e.target.value),
			error: errors.product,
			disabled: !formData.vendor,
		},
		version: {
			label: "Version",
			type: "select",
			placeholder: "Select a version...",
			options: versionOptions,
			value: formData.version,
			onChange: (e) =>
				setFormData((prev) => ({ ...prev, version: e.target.value })),
			error: errors.version,
			disabled: !formData.product,
		},

		hierarchy: {
			label: "Hierarchy",
			type: "text",
			placeholder: "text...",
			value: formData.hierarchy,
			onChange: (e) => handleChange("hierarchy", e.target.value),
			error: errors.hierarchy,
		},

		businessAssets: {
			label: "Business Assets",
			type: "select",
			placeholder: "text...",
			options: assetsList,
			value: formData.businessAssets,
			onChange: (e) => handleChange("businessAssets", e.target.value),
			error: errors.businessAssets,
		},
		assetName: {
			label: "Asset Name",
			type: "text",
			placeholder: "text...",
			value: formData.assetName,
			onChange: (e) => handleChange("assetName", e.target.value),
			error: errors.assetName,
		},
		assetType: {
			label: "Asset Type",
			type: "select",
			placeholder: "text...",
			options: ["Application", 'Operating System', 'Hardware'],
			value: formData.assetType,
			onChange: (e) => handleChange("assetType", e.target.value),
			error: errors.assetType,
		},
		assetCategorization: {
			label: "Asset Categorization",
			type: "select",
			placeholder: "Select...",
			options: ["Public", "Confidential", "Sensitive"],
			value: formData.assetCategorization,
			onChange: (e) => handleChange("assetCategorization", e.target.value),
			error: errors.assetCategorization,
		},
		assetRisk: {
			label: "Asset Risk",
			type: "select",
			placeholder: "Select...",
			options: ["low", "medium", "high"],
			value: formData.assetRisk,
			onChange: (e) => handleChange("assetRisk", e.target.value),
			error: errors.assetRisk,
		},
		regulation: {
			label: "Regulations",
			type: "select",
			placeholder: "Select...",
			options: ["LGPD",
				'CCPA',
				"NYDFS",
				"SOX"],
			value: formData.regulation,
			onChange: (e) => handleChange("regulation", e.target.value),
			error: errors.regulation,
		},
		// GeoLocation: {
		// 	label: "Geo Location",
		// 	type: "text",
		// 	placeholder: "latitude, longitude",
		// 	value: formData.geoLocation,
		// 	onChange: (e) => handleChange("geoLocation", e.target.value),
		// 	error: errors.geoLocation,
		// },
	};
	return (
		<Modal isOpen={isOpen} onClose={onClose} size={size}>
			<Modal.Header>
				<Modal.Title>
					{mode === "edit" ? `Update Record` : `Add New Record`}
				</Modal.Title>
			</Modal.Header>
			{fetchLoading ? (
				<LoadingComponent />
			) : (
				<Modal.Body>
					<div className="modal-two-columns">
						<Form>
							{Object.entries(formFieldsConfig).map(([key, config]) => (
								<Form.Group key={key}>
									<Form.Label>{config.label}</Form.Label>
									<Form.Control {...config} />
									{config.error && (
										<Form.Control.Feedback type="invalid">
											{config.error}
										</Form.Control.Feedback>
									)}
								</Form.Group>
							))}
						</Form>
					</div>
				</Modal.Body>
			)}
			<Modal.Footer>
				<button
					className="btn-cancel"
					onClick={() => {
						onClose();
						resetForm();
					}}
				>
					Cancel
				</button>
				{mode === "add" ? (
					<button onClick={() => handleSubmit()} className="btn-add-new">
						Add
					</button>
				) : (
					<button className="btn-add-new" onClick={""}>
						Save Changes
					</button>
				)}
			</Modal.Footer>
		</Modal>
	);
};

export default ModalHandler;
