import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import swal from "sweetalert";
import http from "../../../resources/http";
import { COST_CATEGORY } from "../../../api/excelUpload";
import CustomTable from "../../../shared/CustomTable";
import SearchHandler from "../../../shared/SearchHandler";
import SearchInput from "../../../shared/SearchInput/SearchInput";
import LoadingComponent from "../../../shared/LoadingDropDownSelection/LoadingComponent";
import ModalHandler from "./ModalHandler";
import { GET_CONTROL_CATEGORY } from "../../../graphql/superAdmin/query/controlConfiguration/query";
import { useQuery } from "@apollo/client";

const CostCategory = () => {
	const url = COST_CATEGORY;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [mode, setMode] = useState("add"); // 'add' or 'edit'
	const [selectedItem, setSelectedItem] = useState(null);

	const [id, setId] = useState();
	const [datas, setData] = useState([]);
	const [fetchLoading, setFetchLoading] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const fieldsToFilterBy = ["category", "description"];

	//data
	async function fetchData() {
		setFetchLoading(true);
		try {
			const response = await http.get(url);
			setData(response.data);
			setFetchLoading(false);
		} catch (error) {
			console.error("Error fetching data:", error);
			setFetchLoading(false);
		}
	}

	// useEffect(() => {
	// 	          // fetchData();
	// }, []);
	const [page, setPage] = useState(0);
	const pageSize = 10; // Number of items per page

	const { data, loading, error, refetch } = useQuery(GET_CONTROL_CATEGORY, {
		variables: { limit: pageSize, offset: page * pageSize },
	});
	const handlePageChange = ({ selected }) => {
		setPage(selected);
		refetch({ limit: pageSize, offset: selected * pageSize });
	};
	const totalItems = data?.control_category_aggregate?.count || 0;

	console.log(data);
	// const [update] = useMutation(UPDATE_CONTROL_TYPE);
	// const [create] = useMutation(ADD_CONTROLTYPE);
	// const [del] = useMutation(DELETE_CONTROL_TYPE);

	const filteredData = SearchHandler(data?.control_category ?? [], searchValue, fieldsToFilterBy);

	const handleInputChange = (newValue) => {
		setSearchValue(newValue);
	};
	// Handle the edit click from the table
	const handleEdit = (id, item) => {
		setSelectedItem(item);
		setId(id);
		setMode("edit");
		setIsModalOpen(true);
	};

	const handleDelete = async (id) => {
		setFetchLoading(true);
		try {
			await http.delete(`${url}/${id}`);
			// fetchData();
			setFetchLoading(false);
			swal("Success", "It has been deleted successfully", "success");
		} catch (error) {
			swal("Failed", "Failed to delete item", "error");
			setFetchLoading(false);
			console.error("Failed to delete item:", error);
		}
	};
	return (
		<main>
			<div className="main-container">
				<div className="table-title ">
					<span className="">Cost Category</span>
					<div className="flex justify-between items-center space-x-4">
						<SearchInput
							searchValue={searchValue}
							setSearchValue={handleInputChange}
						/>
						<button
							className="btn-add-new"
							onClick={() => {
								setSelectedItem(null);
								setMode("add");
								setIsModalOpen(true);
							}}
						>
							<AddIcon />
						</button>
					</div>
				</div>
				{/* table */}
				{fetchLoading ? (
					<LoadingComponent />
				) : searchValue.length > 0 && filteredData.length === 0 ? (
					<div className="alert-no-data">
						No data available for
						<span className="search-value">{searchValue}</span>
						at the moment.
					</div>
				) : (
					<CustomTable
						filteredData={filteredData}
						onEditClick={handleEdit}
						onDeleteClick={handleDelete}
					/>
				)}
				{/* add and edit modal */}
				<ModalHandler
					isOpen={isModalOpen}
					onClose={() => setIsModalOpen(false)}
					mode={mode}
					size="lg"
					selectedItemId={id}
					selectedItem={selectedItem}
					header="Cost Category"
					url={url}
					fetchData={fetchData}
				/>
			</div>
		</main>
	);
};

export default CostCategory;
