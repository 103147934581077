const cpeReducer = (
  state = "empty",
  action
) => {
  switch (action.type) {
    case "CPE":
      return action.payload;
    default:
      return state;
  }
};
export { cpeReducer };
