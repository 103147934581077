import React, { useState } from 'react';
import swal from 'sweetalert';
import http from './../../resources/http';
import { Modal, Button } from 'react-bootstrap';
import { baseURL } from './../../resources/apiClient';
import { USER_SUPPORT } from '../../api/support';
const SubmitIssues = ({ showUpdateToggle, action, payload, fetchData }) => {
  let localStorageData = JSON.parse(localStorage.getItem('cyber-minds'));
  const user = localStorageData?.user?.id;
  const [formState, setFormState] = useState({
    image: null,
    title: payload?.title,
    description: payload?.description,
    urgency: payload?.urgency,
    category: payload?.category,
  });

  const [errorState, setErrorState] = useState({
    title: '',
    additional: '',
    urgency: '',
    category: '',
    description: '',
  });

  const handleTitleChange = (e) => {
    setFormState({ ...formState, title: e.target.value });
    setErrorState({ ...errorState, title: '' });
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setFormState({ ...formState, image: selectedImage });
  };

  const handleDescriptionChange = (e) => {
    setFormState({ ...formState, description: e.target.value });
    setErrorState({ ...errorState, description: '' });
  };

  const hadleSuccess = () => {
    setFormState({
      title: '',
      description: '',
      urgency: '',
      category: '',
      image: null,
    });
    swal('Success', 'It has been added successfully.', 'success');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;

    // Validation
    if (!formState.title.trim()) {
      setErrorState({ ...errorState, title: 'Title cannot be blank' });
      isValid = false;
    }

    if (!formState.urgency || formState.urgency === '') {
      setErrorState({ ...errorState, urgency: 'Urgency is required' });
      isValid = false;
    } else {
      setErrorState({ ...errorState, urgency: '' });
    }

    if (!formState.category || formState.category === '') {
      setErrorState({ ...errorState, category: 'Category is required' });
      isValid = false;
    } else {
      setErrorState({ ...errorState, category: '' });
    }

    if (!formState.description.trim()) {
      setErrorState({
        ...errorState,
        description: 'Description cannot be blank',
      });
      isValid = false;
    }

    if (isValid) {
      const formData = new FormData();
      formData.append('title', formState.title);
      formData.append('description', formState.description);
      formData.append('urgency', formState.urgency);
      formData.append('category', formState.category);
      formData.append('user', user);

      if (formState.image !== null) {
        formData.append('file', formState.image);
      }

      if (action === 'add') {
        // http
        //   .post('https://api-dev.cronusgrx.io/api/support/tickets', formData, {
        //     headers: {
        //       'Content-Type': 'multipart/form-data',
        //     },
        //   })
        //   .then((response) => {
        //     showUpdateToggle();
        //     swal('Success', 'It has been added successfully.', 'success');
        //     console.log(response, 'response');
        //   })
        //   .catch((error) => {
        //     let errorMessage = 'error';
        //     if (
        //       error.response &&
        //       error.response.data &&
        //       error.response.data.error
        //     ) {
        //       errorMessage = error.response.data.error;
        //     }
        //     errorMessage === 'error'
        //       ? hadleSuccess()
        //       : swal('Error', `${errorMessage}`, 'error');
        //   });
      } else {
        // http
        //   .patch(`${baseURL}${USER_SUPPORT}/${payload?.id}`, formData)
        //   .then((res) => {
        //               // fetchData();
        //     showUpdateToggle();
        //     swal('success', 'Ticket Status updated successfully');
        //   })
        //   .catch((err) => {
        //     //   handleModalShowHideMe();
        //     swal('no success');
        //   });
      }
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit} className="space-y-4 w-2/3 mx-auto p-4">
        <div>
          <label htmlFor="title" className="block text-gray-600">
            * Title
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={formState.title}
            onChange={handleTitleChange}
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
          <p className="text-red-500">{errorState.title}</p>
        </div>
        <div>
          <label htmlFor="urgency" className="block text-gray-600">
            * Urgency
          </label>
          <select
            id="urgency"
            name="urgency"
            value={formState.urgency}
            onChange={(e) =>
              setFormState({ ...formState, urgency: e.target.value })
            }
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-3.5 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="">Select Urgency</option>
            <option value="low">Low - Moderate Response</option>
            <option value="urgent">High - Urgent Response</option>
          </select>
          <p className="text-red-500">{errorState.urgency}</p>
        </div>

        <div>
          <label htmlFor="category" className="block text-gray-600">
            * Category
          </label>
          <select
            id="category"
            name="category"
            value={formState.category}
            onChange={(e) =>
              setFormState({ ...formState, category: e.target.value })
            }
            className="bg-gray-50  border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-3.5 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="">Select Category</option>
            <option value="file_upload">File Upload</option>
            <option value="account">Account / Login</option>
            <option value="crud">
              Issue while add, update, delete records
            </option>
          </select>
          <p className="text-red-500">{errorState.category}</p>
        </div>

        <div>
          <label htmlFor="description" className="block text-gray-600">
            * Description
          </label>
          <textarea
            id="description"
            name="description"
            value={formState.description}
            onChange={handleDescriptionChange}
            className="bg-gray-50 border dark:text-gray-400 border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-3.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
          <p className="text-red-500">{errorState.description}</p>
        </div>
        <div>
          <label htmlFor="image" className="block text-gray-600">
            Document (Attach supporting document) *optional
          </label>
          <div className="relative mt-4">
            <input
              type="file"
              id="image"
              name="image"
              onChange={handleImageChange}
              className="w-full sr-only"
            />
            <label
              htmlFor="image"
              className="cursor-pointer mt-10 bg-gray-800 text-white dark:text-gray-400 py-2 px-4 rounded-md transition duration-300 hover:bg-gray-700"
            >
              Choose File
            </label>
          </div>
        </div>
        <Modal.Footer>
          {action === 'edit' ? (
            <Button className="btn-cancel" onClick={() => showUpdateToggle()}>
              Close
            </Button>
          ) : null}

          <button type="submit" className="btn-add-new">
            {action === 'edit' ? 'Save Changes' : 'Submit'}
          </button>
        </Modal.Footer>
      </form>
    </div>
  );
};

export default SubmitIssues;
