import { gql } from '@apollo/client';
export const GET_CONTROL_TYPE = gql`
  query GET_CONTROL_TYPE($limit: Int!, $offset: Int) {
    control_type(limit: $limit, offset: $offset) {
      id
      label
      description 
    }
    control_type_aggregate {
      count
    }
  }
`;
export const GET_CONTROL_DOMAIN = gql`
  query GET_CONTROL_DOMAIN($limit: Int!, $offset: Int) {
    control_domain(limit: $limit, offset: $offset) {
      id
      label
      description 
    }
    control_domain_aggregate {
      count
    }
  }
`;
export const GET_CONTROL_CATEGORY = gql`
  query GET_CONTROL_DOMAIN($limit: Int!, $offset: Int) {
    control_category(limit: $limit, offset: $offset) {
      id
      label
      description 
    }
    control_category_aggregate {
      count
    }
  }
`;
export const GET_ASSET_LIST = gql`
  query GET_ASSET_LIST($limit: Int!, $offset: Int) {
    assets(limit: $limit, offset: $offset) {
      id
      name
    }
    assets_aggregate {
      count
    }
  }
`;