import { toast } from "react-hot-toast";
import { decryptData, encryptData } from "../resources/encryptionPayLoad";

const apiErrorHandler = (error, setErrorFn) => {
	let message = "An unexpected error occurred.";
	if (error.response) {
		// Checks for backend error messages
		const data = error.response.data;
		if (data && data[0]) {
			// Use the API's error message if available
			message = data[0];
		} else {
			switch (error.response.status) {
				case 401:
					message = "Session expired. Please log in again.";
					redirectToLogin();
					break;
				case 403:
					message = "You don't have permission to access this resource.";
					break;
				case 404:
					message = "The requested resource was not found.";
					break;
					default:
						// do nothing
			}
		}
		toast.error(message);
	} else if (error.request) {
		message = "The server did not respond. Please try again later.";
		toast.error(message);
	} else {
		// Error in setting up the request
		toast.error(message);
	}
	console.error(error);
};

const redirectToLogin = () => {
	window.location.href = "/signin";
};

export default apiErrorHandler;
